import axios from 'axios'
import { Handlers } from '../utils'
import MainService from './Main'
import env from '../config'
const service  = '/v1/auth'

const Login = async({payload}) => await axios.post(env.API_URL+service + '/login', payload)
const Signup = async({payload}) => await axios.post(env.API_URL+service + '/signup', payload)
const Get = async({query}) => await axios.get(env.API_URL+service + '/getusers', {headers: await MainService.getTokenHeader()})
const Delete = async({payload}) => await axios.put(env.API_URL+service + '/deleteuser', payload, {headers: await MainService.getTokenHeader()})
const Update = async({payload}) => await axios.put(env.API_URL+service + '/updateuser', payload, {headers: await MainService.getTokenHeader()})


const AuthService = {
    Login          : Handlers.Services(Login),
    Signup         : Handlers.Services(Signup),
    Get            : Handlers.Services(Get),
    Delete         : Handlers.Services(Delete),
    Update         : Handlers.Services(Update)
}

export default AuthService
