
 function IconInformation(props) {
  return (
    <svg
      width={18}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask id="prefix__a" fill="#fff">
        <path d="M8.25 12.75h1.5v-4.5h-1.5v4.5zM9 1.5C4.86 1.5 1.5 4.86 1.5 9c0 4.14 3.36 7.5 7.5 7.5 4.14 0 7.5-3.36 7.5-7.5 0-4.14-3.36-7.5-7.5-7.5zM9 15c-3.308 0-6-2.693-6-6 0-3.308 2.692-6 6-6 3.307 0 6 2.692 6 6 0 3.307-2.693 6-6 6zm-.75-8.25h1.5v-1.5h-1.5v1.5z" />
      </mask>
      <path
        d="M8.25 12.75h1.5v-4.5h-1.5v4.5zM9 1.5C4.86 1.5 1.5 4.86 1.5 9c0 4.14 3.36 7.5 7.5 7.5 4.14 0 7.5-3.36 7.5-7.5 0-4.14-3.36-7.5-7.5-7.5zM9 15c-3.308 0-6-2.693-6-6 0-3.308 2.692-6 6-6 3.307 0 6 2.692 6 6 0 3.307-2.693 6-6 6zm-.75-8.25h1.5v-1.5h-1.5v1.5z"
        fill="#2F80ED"
      />
      <path
        d="M8.25 12.75h-2v2h2v-2zm1.5 0v2h2v-2h-2zm0-4.5h2v-2h-2v2zm-1.5 0v-2h-2v2h2zm0-1.5h-2v2h2v-2zm1.5 0v2h2v-2h-2zm0-1.5h2v-2h-2v2zm-1.5 0v-2h-2v2h2zm0 9.5h1.5v-4h-1.5v4zm3.5-2v-4.5h-4v4.5h4zm-2-6.5h-1.5v4h1.5v-4zm-3.5 2v4.5h4v-4.5h-4zM9-.5C3.755-.5-.5 3.755-.5 9h4c0-3.035 2.465-5.5 5.5-5.5v-4zM-.5 9c0 5.245 4.255 9.5 9.5 9.5v-4A5.503 5.503 0 013.5 9h-4zM9 18.5c5.245 0 9.5-4.255 9.5-9.5h-4c0 3.035-2.465 5.5-5.5 5.5v4zM18.5 9c0-5.245-4.255-9.5-9.5-9.5v4c3.035 0 5.5 2.465 5.5 5.5h4zM9 13c-2.203 0-4-1.797-4-4H1c0 4.412 3.588 8 8 8v-4zM5 9c0-2.203 1.797-4 4-4V1C4.588 1 1 4.588 1 9h4zm4-4c2.203 0 4 1.797 4 4h4c0-4.412-3.588-8-8-8v4zm4 4c0 2.203-1.797 4-4 4v4c4.412 0 8-3.588 8-8h-4zm-4.75-.25h1.5v-4h-1.5v4zm3.5-2v-1.5h-4v1.5h4zm-2-3.5h-1.5v4h1.5v-4zm-3.5 2v1.5h4v-1.5h-4z"
        fill="#6FCF97"
        mask="url(#prefix__a)"
      />
      <path
        d="M8.25 5.25h1.5v1.5h-1.5v-1.5zm0 3h1.5v4.5h-1.5v-4.5z"
        fill="#2F80ED"
      />
      <path
        d="M9 1.5C4.86 1.5 1.5 4.86 1.5 9c0 4.14 3.36 7.5 7.5 7.5 4.14 0 7.5-3.36 7.5-7.5 0-4.14-3.36-7.5-7.5-7.5zM9 15c-3.308 0-6-2.693-6-6 0-3.308 2.692-6 6-6 3.307 0 6 2.692 6 6 0 3.307-2.693 6-6 6z"
        fill="#2F80ED"
      />
    </svg>
  );
}


function IconCheckList(props) {
  return (
    <svg
      width={18}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.5 8.31V9a7.5 7.5 0 11-4.447-6.855M16.5 3L9 10.508l-2.25-2.25"
        stroke="#6FCF97"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function IconStop(props) {
  return (
    <svg
      width={18}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 2.625a6.375 6.375 0 00-5.238 10.01l8.87-8.875A6.342 6.342 0 009 2.625zm5.226 2.722L5.35 14.228a6.375 6.375 0 008.875-8.88zM.375 9a8.625 8.625 0 1117.25 0A8.625 8.625 0 01.375 9z"
        fill="#FB3D5B"
      />
    </svg>
  );
}



function DashboardIcon({width, height}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"  width={width ? width : "16"} height={height? height :"16"} fill="currentColor" class="bi bi-bar-chart-line" viewBox="0 0 16 16">
      <path d="M11 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h1V7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7h1V2zm1 12h2V2h-2v12zm-3 0V7H7v7h2zm-5 0v-3H2v3h2z"/>
    </svg>
  )
}

function CompositionIcon({width, height}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width ? width : "16"} height={height? height :"16"} fill="currentColor" class="bi bi-boxes" viewBox="0 0 16 16">
    <path d="M7.752.066a.5.5 0 0 1 .496 0l3.75 2.143a.5.5 0 0 1 .252.434v3.995l3.498 2A.5.5 0 0 1 16 9.07v4.286a.5.5 0 0 1-.252.434l-3.75 2.143a.5.5 0 0 1-.496 0l-3.502-2-3.502 2.001a.5.5 0 0 1-.496 0l-3.75-2.143A.5.5 0 0 1 0 13.357V9.071a.5.5 0 0 1 .252-.434L3.75 6.638V2.643a.5.5 0 0 1 .252-.434L7.752.066ZM4.25 7.504 1.508 9.071l2.742 1.567 2.742-1.567L4.25 7.504ZM7.5 9.933l-2.75 1.571v3.134l2.75-1.571V9.933Zm1 3.134 2.75 1.571v-3.134L8.5 9.933v3.134Zm.508-3.996 2.742 1.567 2.742-1.567-2.742-1.567-2.742 1.567Zm2.242-2.433V3.504L8.5 5.076V8.21l2.75-1.572ZM7.5 8.21V5.076L4.75 3.504v3.134L7.5 8.21ZM5.258 2.643 8 4.21l2.742-1.567L8 1.076 5.258 2.643ZM15 9.933l-2.75 1.571v3.134L15 13.067V9.933ZM3.75 14.638v-3.134L1 9.933v3.134l2.75 1.571Z"/>
  </svg>
  )
}

function UsersIcon({width, height}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width ? width : "16"} height={height? height :"16"} fill="currentColor" class="bi bi-people" viewBox="0 0 16 16">
      <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z"/>
    </svg>
  )
}

function ProfileIcon({width, height}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width ? width : "16"} height={height? height :"16"} fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
      <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
    </svg>
  )
}



const SvgIcons = {
   IconCheckList,
   IconInformation,
   IconStop,
   DashboardIcon,
   ProfileIcon,
   UsersIcon,
   CompositionIcon
}


export default SvgIcons