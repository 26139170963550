import React, { useContext } from 'react';
import {Redirect, Route} from 'react-router-dom';

import { UserContext } from '../context/user.context';

function AuthGuard({component: Component, ...rest}){
    const user = useContext(UserContext)
    return (
        <Route
            {...rest}
            render={(props) => {
                if(!user.email) return <Component {...props} />
                if(!user.types.includes(3)) return <Component {...props} />
                else return  <Redirect to={'/dashboard'} />
            }}
        />
    )
}

function CentralGuard({component: Component, ...rest}){
    const user = useContext(UserContext)

    return (
        <Route
            {...rest}
            render={(props) => {
                if(user.email) return <Component {...props} />
                else return  <Redirect to='/' />
            }}
        />
    )
}

function  RoleGuard({component: Component, role, path, ...rest}){
    const user = useContext(UserContext);
    return (
        <Route
            path={path}
            render={(props) => {
                if(!user.types.includes(role[0])) return <Redirect to='/' />
                return <Component {...props} {...rest} />
            }}
        />
    )
}


export {
    AuthGuard,
    CentralGuard,
    RoleGuard,
}