import { Environment } from '../enums/enums'

const env = {
    API_URL : 'http://localhost:8080/api',
    IMAGE_BASE_URL : 'https://sustie.s3.eu-north-1.amazonaws.com/'
}

if(process.env.REACT_APP_ENV === Environment.DEVELOPMENT){
    env.API_URL            = 'http://localhost:8080/api'
}

if(process.env.REACT_APP_ENV === Environment.PRODUCTION){
    env.API_URL            = 'https://alan.sustie.io/api'
}


export default env