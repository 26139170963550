import React, { useCallback, useContext, useState } from 'react'
import Papa from 'papaparse';
import {useDropzone} from 'react-dropzone';
import { ProductService } from '../../services';
import { UserContext } from '../../context/user.context';

export default function CSV({setLoader}) {

    const user = useContext(UserContext)
    const [parsedCsvData, setParsedCsvData] = useState([]);

    
  const getImage = (item) => {
    if(item)
    {
      if(item.replace(/\s+/g, '').toLowerCase().includes('fairtrade')) return 'https://sustie.s3.eu-north-1.amazonaws.com/fairtrade.png'
      else if(item.replace(/\s+/g, '').toLowerCase().includes('finn')) return 'https://sustie.s3.eu-north-1.amazonaws.com/finn.png'
      else if(item.replace(/\s+/g, '').toLowerCase().includes('tise')) return 'https://sustie.s3.eu-north-1.amazonaws.com/tise.png'
      else if(item.replace(/\s+/g, '').toLowerCase().includes('fjong')) return 'https://sustie.s3.eu-north-1.amazonaws.com/fjong.png'
      else if(item.replace(/\s+/g, '').toLowerCase().includes('holzweiler')) return 'https://sustie.s3.eu-north-1.amazonaws.com/holzweiler.png'
      else if(item.replace(/\s+/g, '').toLowerCase().includes('nordicswan')) return 'https://sustie.s3.eu-north-1.amazonaws.com/nordicswan.png'
      else if(item.replace(/\s+/g, '').toLowerCase().includes('oeko')) return 'https://sustie.s3.eu-north-1.amazonaws.com/oeko.png'
      else if(item.replace(/\s+/g, '').toLowerCase().includes('newmovement')) return 'https://sustie.s3.eu-north-1.amazonaws.com/newmovement.png'
      else if(item.replace(/\s+/g, '').toLowerCase().includes('gots')) return 'https://sustie.s3.eu-north-1.amazonaws.com/gots.png'
      else return ''
    }
  } 

    const parseFile = file => {
        Papa.parse(file, {
          header: true,
          complete: async results => {
            setLoader(true);
              for (const element of results.data) {
                if(element.brand){
                    let payload = {
                            brandName      : element.brand,
                            productName    : element.product,
                            certification1 : {
                                                certificationName      : element.certificationname1,
                                                certificationImage     : getImage(element.certificationname1),
                                                certificationSubtext   : element.certificationsubtext1 ,
                                                certificationLink      : element.certificationlink1,
                                            },
                            certification2 : {
                                                certificationName      : element.certificationname2,
                                                certificationImage     : getImage(element.certificationname2),
                                                certificationSubtext   : element.certificationsubtext2,
                                                certificationLink      : element.certificationlink2
                                            },
                            certification3 : {
                                                certificationName      : element.certificationname3,
                                                certificationImage     : getImage(element.certificationname3),
                                                certificationSubtext   : element.certificationsubtext3 ,
                                                certificationLink      : element.certificationlink3,
                                            },
                            certification4 : {
                                                certificationName      : element.certificationname4,
                                                certificationImage     : getImage(element.certificationname4),
                                                certificationSubtext   : element.certificationsubtext4 ,
                                                certificationLink      : element.certificationlink4,
                                            },
                            composition1 : {
                                                compositionName     : element.compositionname1,
                                                compositionNumber   : element.compositionnumber1,
                                                compositionUrl      : element.compositionurl1 ,
                                        },
                            composition2 : {
                                                compositionName     : element.compositionname2,
                                                compositionNumber   : element.compositionnumber2,
                                                compositionUrl      : element.compositionurl2,
                                        },
                            composition3 : {
                                                compositionName     : element.compositionname3 || '',
                                                compositionNumber   : element.compositionnumber3 || '0',
                                                compositionUrl      : element.compositionurl3,
                                        },
                            composition4 : {
                                                compositionName     : element.compositionname4,
                                                compositionNumber   : element.compositionnumber4,
                                                compositionUrl      : element.compositionurl4,
                                        },
                            link1       : {
                                                linkName    : element.linkname1,
                                                linkImage   : getImage(element.linkname1),
                                                linkUrl     : element.link1, 
                                                linkSubText : element.linksubtext1
                                        },
                            link2       : {
                                                linkName    : element.linkname2,
                                                linkImage   : getImage(element.linkname2),
                                                linkUrl     : element.link2, 
                                                linkSubText : element.linksubtext2
                                        },
                            link3       : {
                                                linkName    : element.linkname3,
                                                linkImage   : getImage(element.linkname3),
                                                linkUrl     : element.link3, 
                                                linkSubText : element.linksubtext3
                                        },
                            link4       : {
                                                linkName    : element.linkname4,
                                                linkImage   : getImage(element.linkname4),
                                                linkUrl     : element.link4,
                                                linkSubText : element.linksubtext4
                                        },
                        }
                    payload.userId = user._id;
                    
                    if(element._id)
                    {
                        const getproduct = await ProductService.Get();
                        if(getproduct.error) return setLoader(false);;

                        let product;
                        getproduct.response.data.map((item, idx)=>{
                            if(item._id === element._id) product = item
                        })

                        if(!product) return setLoader(false);;

                        payload._id = element._id;
                        const updatedProduct = ProductService.Update({payload})
                    }
                    else
                    {
                        const {error , response} = await ProductService.Create({payload})
                        console.log('error or response', error, response)
                    }  
                }
                  
            }
            setLoader(false);
            window.location.reload()
          },
        });
    };

    const onDrop = useCallback(acceptedFiles => {
        if (acceptedFiles.length) {
          parseFile(acceptedFiles[0]);
        }
    }, []);

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        onDrop,
        accept: 'text/csv',
    });


    return (
        <div className="App">
            <div
            {...getRootProps({
                className: `dropzone
                ${isDragAccept && 'dropzoneAccept'}
                ${isDragReject && 'dropzoneReject'}`,
            })}
            >
            <input {...getInputProps()} />
            {isDragActive ? (
                <div>Drop the files here ...</div>
            ) : (
                <div>Import data from .csv file on one Click </div>
            )}
            </div>
        </div>
    )
}
