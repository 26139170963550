import React, { useEffect, useState } from 'react'
import { Line } from "react-chartjs-2";
import {Bar} from 'react-chartjs-2';
import {Chart as ChartJS} from 'chart.js/auto'
import { AuthService, ProductService } from '../../services';
import { PngIcons } from '../../icons';
import Loader from '../../components/Loader';


export default function Dashboard(props) {
  
  const [products, setProducts] = useState();
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState();

  const [generalStats, setGeneralStats] = useState();
  const [certificationStats, setCertificationStats]  = useState();
  const [compositionStats, setCompositionStats]  = useState();
  const [linkStats, setLinkStats]  = useState();




  useEffect(async ()=>{
    setLoader(true);
    const {response, error} = await ProductService.Get();
    if(response) {
      setProducts(response.data);
      const user = await AuthService.Get();
      if(user.response) setUsers(user.response.data);
      setLoader(false);

      let certificationDataLabels = [];
      let certificationData =[];

      let compositionDataLabels = [];
      let compositionData =[];

      let linkDataLabels = [];
      let linkData =[];

      const key = 'brandName'
      const arrayUniqueByKey = [...new Map(response.data.map(item =>
        [item[key], item])).values()];

      response.data.map((element, idx)=>{
        if(element.certifications)
        {
          element.certifications.map((certificate, idx)=>{
            if(certificate.certificationName && certificate.certificationName !== " " && certificationDataLabels.includes(certificate.certificationName))
            {
              certificationData[idx] = certificationData[idx] + 1;
            }
            else if(certificate.certificationName && certificate.certificationName !== " ")
            {
              certificationDataLabels.push(certificate.certificationName)
              certificationData.push(1)
            }
          })
        }

        if(element.compositions){
          element.compositions.map((composition, idx)=>{
            if(composition.compositionName && composition.compositionName !== " " && compositionDataLabels.includes(composition.compositionName))
            {
              compositionData[idx] = compositionData[idx] + 1;
            }
            else if(composition.compositionName && composition.compositionName !== " ")
            {
              compositionDataLabels.push(composition.compositionName)
              compositionData.push(1)
            }
          })
        }

        
        if(element.links){
          element.links.map((link, idx)=>{
            if(link.linkName && link.linkName !== " " && linkDataLabels.includes(link.linkName))
            {
              linkData[idx] = linkData[idx] + 1;
            }
            else if(link.linkName && link.linkName !== " ")
            {
              linkDataLabels.push(link.linkName)
              linkData.push(1)
            }
          })
        }
      })


      setCertificationStats({
        labels: certificationDataLabels,
        datasets: [
          {
            label: "Certification Data",
            data: certificationData,
            fill: true,
            backgroundColor: "#C4E7F4",
            borderColor: "#3bafda"
          },
        ]
      })

      setCompositionStats({
        labels: compositionDataLabels,
        datasets: [
          {
            label: 'Composition Data',
            backgroundColor: '#1ABC9C',
            borderWidth: 0,
            data: compositionData
          }
        ]
      })

      setLinkStats({
        labels: linkDataLabels,
        datasets: [
          {
            label: 'Better Sustainable Data',
            backgroundColor: '#f5cddd',
            borderColor: '#F672A7',
            fill: true,
            data: linkData
          }
        ]
      })

      setGeneralStats({'products' : response.data.length , 'brands' : arrayUniqueByKey.length, 'users' : user.response.data.length, 'certifications': certificationDataLabels.length, 'links' : linkDataLabels.length, 'compositions' : compositionDataLabels.length })
      
    }
    setLoader(false);
  },[])



  return (
    <div className='dashboard'>
      
        <div className='h4 sideheader'>Dashboard</div>

        {
          loader ? <Loader/> :

          <div className='content'>
            <div className="dashboardContent">

              <div className='row justify-content-between p-2'>
                <div className='col-3 '>
                  <div className='col-12 box  d-flex flex-row justify-content-between'>
                      <div><img src={PngIcons.product} width='60px' height='60px'></img></div>
                      <div className=''>
                        <div  className='h2'> {generalStats?.products} </div>
                        <div  className='caption'> Total Products </div>
                      </div>
                  </div>
                </div>

                <div className='col-3 '>
                  <div className='col-12 box  d-flex flex-row justify-content-between'>
                      <div><img src={PngIcons.brand} width='60px' height='60px'></img></div>
                      <div className='d-flex flex-column justify-content-center'>
                        <div className='h2'> {generalStats?.brands} </div>
                        <div className='caption'> Total Brands </div>
                      </div>
                  </div>
                </div>

                <div className='col-3 '>
                  <div className='col-12 box  d-flex flex-row justify-content-between'>
                      <div><img src={PngIcons.user} width='60px' height='60px'></img></div>
                      <div className='d-flex flex-column justify-content-center'>
                        <div className='h2'> {generalStats?.users} </div>
                        <div className='caption'> Total Users </div>
                      </div>
                  </div>
                </div>
              </div>

              <div className='row justify-content-between p-2'>
                <div className='col-3 '>
                    <div className='col-12 box  d-flex flex-row justify-content-between'>
                      <div><img src={PngIcons.certificate} width='60px' height='60px'></img></div>
                      <div className='d-flex flex-column justify-content-center'>
                        <div  className='h2'> {generalStats?.certifications} </div>
                        <div  className='caption'> Total Certifications </div>

                      </div>
                    </div>
                </div>

                <div className='col-3 '>
                  <div className='col-12 box  d-flex flex-row justify-content-between'>
                      <div><img src={PngIcons.composition} width='60px' height='60px'></img></div>
                      <div className='d-flex flex-column justify-content-center'>
                        <div className='h2'> {generalStats?.compositions} </div>
                        <div className='caption'> Total Compostitions </div>
                      </div>
                  </div>
                </div>

                <div className='col-3 '>
                  <div className='col-12 box  d-flex flex-row justify-content-between'>
                      <div><img src={PngIcons.link} width='60px' height='60px'></img></div>
                      <div className='d-flex flex-column justify-content-center'>
                        <div className='h2'> {generalStats?.links} </div>
                        <div className='caption'> Total Sustainable Links </div>
                      </div>
                  </div>
                </div>
              </div>


              <div className='row p-2 justify-content-between '>
                <div className='col-6 '>
                  <div className='col-12 box'>
                    {compositionStats && <Bar className='mt-3' data={compositionStats} options={{
                                              scales: {
                                                yAxis: {
                                                  min: 0,
                                                  max: Math.max(...compositionStats.datasets[0].data) + 1,
                                                },
                                                x: {
                                                  ticks: {
                                                      font: {
                                                          size: 14,
                                                      }
                                                  }
                                                }
                                              },
                                              plugins: {
                                                  legend: {
                                                      labels: {
                                                          // This more specific font property overrides the global property
                                                          font: {
                                                              size: 16
                                                          }
                                                      }
                                                  }
                                              }
                                          }}/>}
                  </div>
                </div>
                <div className='col-6 '>
                  <div className='col-12 box'>
                    {linkStats && <Line className='mt-3' data={linkStats}  options={{
                                              scales: {
                                                yAxis: {
                                                  min: 0,
                                                  max: Math.max(...linkStats.datasets[0].data) + 1,
                                                },
                                                x: {
                                                  ticks: {
                                                      font: {
                                                          size: 14,
                                                      }
                                                  }
                                                }
                                              },
                                              plugins: {
                                                  legend: {
                                                      labels: {
                                                          // This more specific font property overrides the global property
                                                          font: {
                                                              size: 16
                                                          }
                                                      }
                                                  }
                                              }
                                          }} />}
                  </div>
                </div>
              </div>

              <div className='row p-2 justify-content-between '>
                <div className='col-12 box'>
                {certificationStats && <Line data={certificationStats}  options={{
                                            scales: {
                                              yAxis: {
                                                min: 0,
                                                max: Math.max(...certificationStats.datasets[0].data) + 1,
                                              },
                                              x: {
                                                ticks: {
                                                    font: {
                                                        size: 14,
                                                    }
                                                }
                                              }
                                            },
                                            plugins: {
                                                legend: {
                                                    labels: {
                                                        // This more specific font property overrides the global property
                                                        font: {
                                                            size: 16
                                                        }
                                                    }
                                                }
                                            }
                                        }} />}
                </div>
              </div>
            </div>
          </div>
        }
       
  </div>
  )
}
