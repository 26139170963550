import * as yup from 'yup';

const composition = yup.object({
    compositionName      : yup.string().required('This field is required'),
    compositionNumber    : yup.string().required('This field is required')
})

const Create = yup.object({
    brandName       : yup.string().required('This field is required'),
    productName     : yup.string().required('This field is required'),
    certification1  : yup.object(),
    certification2  : yup.object(),
    certification3  : yup.object(),
    certification4  : yup.object(),
    composition1    : composition.required(),
    composition2    : yup.object().nullable(),
    composition3    : yup.object().nullable(),
    composition4    : yup.object().nullable(),
    _id             : yup.string().nullable(),
    edit            : yup.boolean().nullable(),
})

export {
    Create,
}