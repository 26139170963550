
const Environment = {
    DEVELOPMENT     : 'development',
    STAGING         : 'staging',
    PRODUCTION      : 'production'
}

const Server = {
    DEV_URL     : 'v2dev.walletly.ai',
    PROD_URL    : 'api.walletly.ai'
}

const ApiVersions = {
    V3 : 'v3',
    V4 : 'v4'
}

const ResponseStatus = {
    SUCCESS         : 200,
    BAD_REQUEST     : 400,
    UNAUTHORIZED    : 401,
    FORBIDDEN       : 403,
    NOT_FOUND       : 404,
    INTERNAL_ERROR  : 500,
}

const Months = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

const Images = {
    LOGO_FILE_SIZE        : 5 * 1024 * 1024,
    LOGO_SUPPORTED_FORMAT : ['image/jpg', 'image/jpeg', 'image/png'],
    ICON_FILE_SIZE        : 2 * 1024 * 1024
}

const TOASTER_DELAY = 4 * 1000

const Operands = {
    GREATER   : 'greater',
    LESSER    : 'lesser',
    EQUAL     : 'equal',
    NOT_EQUAL : 'not_equal'
}



export {
  
    Environment,
    Server,
    ApiVersions,
    ResponseStatus,
    Months,
    Images,
    TOASTER_DELAY,
    Operands,

}