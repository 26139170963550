import localforage from 'localforage';
import { useEffect, useState } from 'react';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { UserContext } from '../context/user.context';
import { utils } from '../utils';


function DropDown({user}){

    const options = [
        'Logout'
    ];

    const handleChange = (e) => {
        if(e.value === 'Logout') utils.Logout()
    }

    return (
        <>
            {user ? 
                <Dropdown options={options} onChange={handleChange} className='dropDown' id='dropDown'  placeholder={user.fullName} />
            : ''}
        </>
    )
}

export default DropDown;