import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { UserContext } from '../context/user.context'
import { PngIcons, SvgIcons } from '../icons'
import { utils } from '../utils'

export default function Sidebar(props) {
    const history = useHistory();
    const user = useContext(UserContext)
    let check = history.location.pathname.split('/')
    return (
        <>
        {
            (props.show) ? 
                <div className='d-flex flex-column sidebar'>
                    <div className='heading'><span className='logo mt-3'> SUSTIE </span></div>
                    
                    <div className='navigation'>
                        <div className={(history.location.pathname.includes('dashboard') && check[check.length -1] === 'dashboard')? 'activeLink p2 link' : ' p2 link'}>
                            <span className='cp'><Link to='/'> <SvgIcons.DashboardIcon/>&nbsp;&nbsp; Dashboard </Link></span>
                        </div>
                        <div className={(history.location.pathname.includes('composition') || history.location.pathname.includes('product')) ? 'activeLink p2 link' : 'p2 link'}>
                            <span className='cp'><Link to='/dashboard/composition'><SvgIcons.CompositionIcon/>&nbsp;&nbsp; Composition </Link></span>
                        </div>
                        {user.types.includes(2) ? <div  className={history.location.pathname.includes('user') ? 'activeLink p2 link' : 'p2 link'}>
                            <span className='cp'><Link to='/dashboard/users'><SvgIcons.UsersIcon/>&nbsp;&nbsp; Users  </Link></span>
                        </div> : ''}
                        <div  className={history.location.pathname.includes('profile') ? 'activeLink p2 link' : 'p2 link'}>
                            <span className='cp'><Link to='/dashboard/profile'><SvgIcons.ProfileIcon/>&nbsp;&nbsp; Profile  </Link></span>
                        </div>
                    </div>
                </div>
            : 

                <div className='d-flex flex-column partialSideBar'>
                <div className='heading'><img src={PngIcons.logo} alt='' width='48px' height='48px'/></div>
                
                <div className='navigation'>
                    <div className={(history.location.pathname.includes('dashboard') && check[check.length -1] === 'dashboard')? 'activeLink p2 link' : ' p2 link'}>
                        <span className='cp'><Link to='/'> <SvgIcons.DashboardIcon width='20' height='20'/></Link></span>
                    </div>
                    <div className={(history.location.pathname.includes('composition') || history.location.pathname.includes('product')) ? 'activeLink p2 link' : 'p2 link'}>
                        <span className='cp'><Link to='/dashboard/composition'><SvgIcons.CompositionIcon width='20' height='20'/></Link></span>
                    </div>
                    {user.types.includes(2) ? <div  className={history.location.pathname.includes('user') ? 'activeLink p2 link' : 'p2 link'}>
                        <span className='cp'><Link to='/dashboard/users'><SvgIcons.UsersIcon width='20' height='20'/> </Link></span>
                    </div> : ''}
                    <div  className={history.location.pathname.includes('profile') ? 'activeLink p2 link' : 'p2 link'}>
                        <span className='cp'><Link to='/dashboard/profile'><SvgIcons.ProfileIcon width='20' height='20'/></Link></span>
                    </div>
                </div>
            </div>
        }
        </>
    )
}
