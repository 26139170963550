import { useFormik } from 'formik';
import React, { useState } from 'react'
import TextField from '@mui/material/TextField';
import { ProductVld } from '../../validation';
import Button from '@mui/material/Button';
import { CircularProgress, createTheme } from '@mui/material';
import { ProductService } from '../../services';
import { useHistory } from 'react-router-dom';
import localforage from 'localforage';
import { ThemeProvider } from '@emotion/react';

export default function AddProduct(props) {

    const history = useHistory();

    const [certification1file, setCertification1File] = useState();
    const [certification1fileName, setCertification1FileName] = useState("");
    const [certification2file, setCertification2File] = useState();
    const [certification2fileName, setCertification2FileName] = useState("");
    const [certification3file, setCertification3File] = useState();
    const [certification3fileName, setCertification3FileName] = useState("");
    const [certification4file, setCertification4File] = useState();
    const [certification4fileName, setCertification4FileName] = useState("");

    const [swap1file, setSwap1File] = useState();
    const [swap1fileName, setSwap1FileName] = useState("");
    const [swap2file, setSwap2File] = useState();
    const [swap2fileName, setSwap2FileName] = useState("");
    const [swap3file, setSwap3File] = useState();
    const [swap3fileName, setSwap3FileName] = useState("");
    const [swap4file, setSwap4File] = useState();
    const [swap4fileName, setSwap4FileName] = useState("");
    
    const theme = createTheme({
        status: {
          danger: '#e53e3e',
        },
        palette: {
          primary: {
            main: '#3bafda',
            darker: '#3295b9',
            contrastText: '#fff',
          },
          neutral: {
            main: '#fff',
            contrastText: '#fff',
          },
        },
      });

    const saveFile = (e, name) => {

        var output = document.getElementById(name);
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = function() {
          URL.revokeObjectURL(output.src)
        }

        if(name === 'certification1')
        {
            setCertification1File(e.target.files[0]);
            setCertification1FileName(e.target.files[0].name);
        }
        else if(name === 'certification2')
        {
            setCertification2File(e.target.files[0]);
            setCertification2FileName(e.target.files[0].name);
        }
        else if(name === 'certification3')
        {
            setCertification3File(e.target.files[0]);
            setCertification3FileName(e.target.files[0].name);
        }
        else if(name === 'certification4')
        {
            setCertification4File(e.target.files[0]);
            setCertification4FileName(e.target.files[0].name);
        }
        else if(name === 'swap1')
        {
            setSwap1File(e.target.files[0]);
            setSwap1FileName(e.target.files[0].name);
        }
        else if(name === 'swap2')
        {
            setSwap2File(e.target.files[0]);
            setSwap2FileName(e.target.files[0].name);
        }
        else if(name === 'swap3')
        {
            setSwap3File(e.target.files[0]);
            setSwap3FileName(e.target.files[0].name);
        }
        else if(name === 'swap4')
        {
            setSwap4File(e.target.files[0]);
            setSwap4FileName(e.target.files[0].name);
        }
    };

    let product = history.location && history.location.state && history.location.state.product;

    const [state, setState] = useState({
        loader   : false,
        apiError : ''
      });
    
    const initState = {
        brandName           : product ? product.brandName : '',
        productName         : product ? product.productName : '',
        certification1      : {
                                    certificationImage      : (product && product.certifications[0] && product.certifications[0].certificationImage) ? product.certifications[0].certificationImage : '',
                                    certificationName       : (product && product.certifications[0] && product.certifications[0].certificationName) ? product.certifications[0].certificationName : '',
                                    certificationSubtext    : (product && product.certifications[0] && product.certifications[0].certificationSubtext) ? product.certifications[0].certificationSubtext : '',
                                    certificationLink       : (product && product.certifications[0] && product.certifications[0].certificationLink) ? product.certifications[0].certificationLink : '',
                                },
        certification2      : {
                                    certificationImage      : (product && product.certifications[1] && product.certifications[1].certificationImage) ? product.certifications[1].certificationImage : '',
                                    certificationName       : (product && product.certifications[1] && product.certifications[1].certificationName) ? product.certifications[1].certificationName : '',
                                    certificationSubtext    : (product && product.certifications[1] && product.certifications[1].certificationSubtext) ? product.certifications[1].certificationSubtext : '',
                                    certificationLink       : (product && product.certifications[1] && product.certifications[1].certificationLink) ? product.certifications[1].certificationLink : '',
                                },
        certification3      : {
                                    certificationImage      : (product && product.certifications[2] && product.certifications[2].certificationImage) ? product.certifications[2].certificationImage : '',
                                    certificationName       : (product && product.certifications[2] && product.certifications[2].certificationName) ? product.certifications[2].certificationName : '',
                                    certificationSubtext    : (product && product.certifications[2] && product.certifications[2].certificationSubtext) ? product.certifications[2].certificationSubtext : '',
                                    certificationLink       : (product && product.certifications[2] && product.certifications[2].certificationLink) ? product.certifications[2].certificationLink : '',
                                },
        certification4      : {
                                    certificationImage      : (product && product.certifications[3] && product.certifications[3].certificationImage) ? product.certifications[3].certificationImage : '',
                                    certificationName       : (product && product.certifications[3] && product.certifications[3].certificationName) ? product.certifications[3].certificationName : '',
                                    certificationSubtext    : (product && product.certifications[3] && product.certifications[3].certificationSubtext) ? product.certifications[3].certificationSubtext : '',
                                    certificationLink       : (product && product.certifications[3] && product.certifications[3].certificationLink) ? product.certifications[3].certificationLink : '',
                                },
        composition1        : {
                                    compositionName : (product && product.compositions[0] && product.compositions[0].compositionName) ? product.compositions[0].compositionName : '',
                                    compositionNumber: (product && product.compositions[0] && product.compositions[0].compositionNumber) ? product.compositions[0].compositionNumber : '',
                                    compositionUrl: (product && product.compositions[0] && product.compositions[0].compositionUrl) ? product.compositions[0].compositionUrl : '',
                            },
        composition2        : {
                                    compositionName : (product && product.compositions[1] && product.compositions[1].compositionName) ? product.compositions[1].compositionName : '',
                                    compositionNumber: (product && product.compositions[1] && product.compositions[1].compositionNumber) ? product.compositions[1].compositionNumber : '',
                                    compositionUrl: (product && product.compositions[1] && product.compositions[1].compositionUrl) ? product.compositions[1].compositionUrl : '',
                            },
        composition3        : {
                                    compositionName : (product && product.compositions[2] && product.compositions[2].compositionName) ? product.compositions[2].compositionName : '',
                                    compositionNumber: (product && product.compositions[2] && product.compositions[2].compositionNumber) ? product.compositions[2].compositionNumber : '',
                                    compositionUrl: (product && product.compositions[2] && product.compositions[2].compositionUrl) ? product.compositions[2].compositionUrl : '',
                            },
        composition4        : {
                                    compositionName : (product && product.compositions[3] && product.compositions[3].compositionName) ? product.compositions[3].compositionName : '',
                                    compositionNumber: (product && product.compositions[3] && product.compositions[3].compositionNumber) ? product.compositions[3].compositionNumber : '',
                                    compositionUrl: (product && product.compositions[3] && product.compositions[3].compositionUrl) ? product.compositions[3].compositionUrl : '',
                            },
        link1               : {
                                    linkImage   : (product && product.links[0] && product.links[0].linkImage) ? product.links[0].linkImage : '',
                                    linkName  : (product && product.links[0] && product.links[0].linkName) ? product.links[0].linkName : '',
                                    linkUrl   : (product && product.links[0] && product.links[0].linkUrl) ? product.links[0].linkUrl : '',
                                    linkSubText : (product && product.links[0] && product.links[0].linkSubText) ? product.links[0].linkSubText : '',
                            },
        link2               : {
                                    linkImage   : (product && product.links[1] && product.links[1].linkImage) ? product.links[1].linkImage : '',
                                    linkName  : (product && product.links[1] && product.links[1].linkName) ? product.links[1].linkName : '',
                                    linkUrl   : (product && product.links[1] && product.links[1].linkUrl) ? product.links[1].linkUrl : '',
                                    linkSubText : (product && product.links[1] && product.links[1].linkSubText) ? product.links[1].linkSubText : '',
                            },
        link3               : {
                                    linkImage   : (product && product.links[2] && product.links[2].linkImage) ? product.links[2].linkImage : '',
                                    linkName  : (product && product.links[2] && product.links[2].linkName) ? product.links[2].linkName : '',
                                    linkUrl   : (product && product.links[2] && product.links[2].linkUrl) ? product.links[2].linkUrl : '',
                                    linkSubText : (product && product.links[2] && product.links[2].linkSubText) ? product.links[2].linkSubText : '',
                            },
        link4               : {
                                    linkImage   : (product && product.links[3] && product.links[3].linkImage) ? product.links[3].linkImage : '',
                                    linkName    : (product && product.links[3] && product.links[3].linkName) ? product.links[3].linkName : '',
                                    linkUrl     : (product && product.links[3] && product.links[3].linkUrl) ? product.links[3].linkUrl : '',
                                    linkSubText : (product && product.links[3] && product.links[3].linkSubText) ? product.links[3].linkSubText : '',
                            },
        edit                : product && product.edit ? product.edit : false,
        _id                 : product && product._id ? product._id : ''
    }

    const handleSubmit = async () => {
        setState({loader: true});
        const desiredPath = `${formik.values.brandName}/${formik.values.productName}`
       
        let payload = {...formik.values}
        
        let certification1ImageFile, certification2ImageFile, certification3ImageFile, certification4ImageFile
        let swap1ImageFile, swap2ImageFile, swap3ImageFile, swap4ImageFile
        
        if(certification1file && certification1fileName){
            certification1ImageFile = new FormData();
            certification1ImageFile.append('image', certification1file, certification1file.name)
            let image1 = await ProductService.Upload({ payload: certification1ImageFile, desiredPath})
            payload.certification1.certificationImage = image1.response.data
            formik.setValues({...formik.values, certification1 : {...formik.values.certification1, certificationImage:  image1.response.data}})
        }
        
        if(certification2file && certification2fileName){
            certification2ImageFile = new FormData();
            certification2ImageFile.append('image', certification2file, certification2file.name)
            let image2 = await ProductService.Upload({ payload: certification2ImageFile, desiredPath})
            payload.certification2.certificationImage =  image2.response.data
            formik.setValues({...formik.values, certification2 : {...formik.values.certification2, certificationImage:  image2.response.data}})
        }

        if(certification3file && certification3fileName){
            certification3ImageFile = new FormData();
            certification3ImageFile.append('image', certification3file, certification3file.name)
            let image3 = await ProductService.Upload({ payload: certification3ImageFile, desiredPath})
            payload.certification3.certificationImage =  image3.response.data
            formik.setValues({...formik.values, certification3 : {...formik.values.certification3, certificationImage:  image3.response.data}})
        }

        if(certification4file && certification4fileName){
            certification4ImageFile = new FormData();
            certification4ImageFile.append('image', certification4file, certification4file.name)
            let image4 = await ProductService.Upload({ payload: certification4ImageFile, desiredPath})
            payload.certification4.certificationImage =  image4.response.data
            formik.setValues({...formik.values, certification4 : {...formik.values.certification4, certificationImage:  image4.response.data}})
        }

        if(swap1file && swap1fileName){
            swap1ImageFile = new FormData();
            swap1ImageFile.append('image', swap1file, swap1fileName.name)
            let image1 = await ProductService.Upload({ payload: swap1ImageFile, desiredPath})
            payload.link1.linkImage = image1.response.data
            formik.setValues({...formik.values, link1 : {...formik.values.link1, linkImage:  image1.response.data}})
        }

        if(swap2file && swap2fileName){
            swap2ImageFile = new FormData();
            swap2ImageFile.append('image', swap2file, swap2fileName.name)
            let image2 = await ProductService.Upload({ payload: swap2ImageFile, desiredPath})
            payload.link2.linkImage = image2.response.data
            formik.setValues({...formik.values, link2 : {...formik.values.link2, linkImage:  image2.response.data}})
        }

        if(swap3file && swap3fileName){
            swap3ImageFile = new FormData();
            swap3ImageFile.append('image', swap3file, swap3fileName.name)
            let image3 = await ProductService.Upload({ payload: swap3ImageFile, desiredPath})
            payload.link3.linkImage = image3.response.data
            formik.setValues({...formik.values, link3 : {...formik.values.link3, linkImage:  image3.response.data}})
        }

        if(swap4file && swap4fileName){
            swap4ImageFile = new FormData();
            swap4ImageFile.append('image', swap4file, swap4fileName.name)
            let image4 = await ProductService.Upload({ payload: swap4ImageFile, desiredPath})
            payload.link4.linkImage = image4.response.data
            formik.setValues({...formik.values, link4 : {...formik.values.link4, linkImage:  image4.response.data}})
        }
      
        if(payload.edit)
        {
            delete payload.edit;
            const user = await localforage.getItem('user');
            payload.userId = user._id;
            const {error , response} = await ProductService.Update({payload})
            if(error) return setState({apiError: error.error || error.message, loader: false})
        }
        else 
        {
            delete payload.edit;
            delete payload._id;
            const user = await localforage.getItem('user');
            payload.userId = user._id;
            const {error , response} = await ProductService.Create({payload})
            if(error) return setState({apiError: error.error || error.message, loader: false})
        }

        setState({...state, loader: false})
        return history.push('/dashboard/composition');
  

    }

    const formik = useFormik({
        initialValues    : { ...initState },
        onSubmit         : handleSubmit,
        validationSchema : ProductVld.Create,
        isInitialValid   : false
    })
  return (
    <div className='addProduct' style={{height: '100vh'}}>
        <form onSubmit={formik.handleSubmit} style={{height: '100vh'}}>
            <div className='h4 sideheader'>Add Product</div>
                <div className='content'>
                    <div className='cardBack'>
                    <div className='p-2' style={{height: '75vh', overflowY: 'scroll', overflowX:'hidden'}}>
                        <div className='h3'>Product Detail</div>
                        <div className='row mt-3'>
                            <div className='col-6'>
                                    <input 
                                        type        ="text"
                                        name        ="brandName" 
                                        placeholder = "Enter Brand Name"
                                        value       = {formik.values.brandName}
                                        onChange    = {formik.handleChange}
                                        error       = {formik.touched.brandName && formik.errors.brandName}
                                        helperText  = {formik.touched.brandName && formik.errors.brandName ? formik.errors.brandName : ''}
                                    />
                            </div>
                            <div className='col-6'>
                                    <input 
                                        type        ="text"
                                        name        ="productName" 
                                        placeholder = "Enter Product Name"
                                        value       = {formik.values.productName}
                                        onChange    = {formik.handleChange}
                                        error       = {formik.touched.productName && formik.errors.productName}
                                        helperText  = {formik.touched.productName && formik.errors.productName ? formik.errors.productName : ''}
                                    />
                            </div>
                        </div> 
                        
                        <div className='mt-5  h3'>Composition</div>

                        <div className='row mt-3'>
                            <div className='col-12'>
                                <div className='row'>
                                    <div className='col-4'>
                                        <input 
                                            placeholder ="Enter Composition Name" 
                                            type        ="text"
                                            name        ="composition1.compositionName" 
                                            value       = {formik.values.composition1 && formik.values.composition1.compositionName}
                                            onChange    = {formik.handleChange}
                                            error       = {formik.errors.composition1 && formik.touched.composition1 && formik.touched.composition1.compositionName && formik.errors.composition1.compositionName}
                                            helperText  = {formik.errors.composition1 && formik.touched.composition1 && formik.touched.composition1.compositionName && formik.errors.composition1.compositionName ? formik.errors.composition1.compositionName : ''}
                                        />
                                    </div>
                                    <div className='col-4'>
                                        <input 
                                            placeholder ="Enter Composition Number" 
                                            type        ="text"
                                            name        ="composition1.compositionNumber" 
                                            value       = {formik.values.composition1 && formik.values.composition1.compositionNumber}
                                            onChange    = {formik.handleChange}
                                            error       = {formik.errors.composition1 && formik.errors.composition1 && formik.touched.composition1 && formik.touched.composition1.compositionNumber && formik.errors.composition1.compositionNumber}
                                            helperText  = {formik.errors.composition1 && formik.errors.composition1 && formik.touched.composition1 && formik.touched.composition1.compositionNumber && formik.errors.composition1.compositionNumber ? formik.errors.composition1.compositionNumber : ''}
                                        />
                                    </div>
                                    <div className='col-4'>
                                        <input 
                                            
                                            placeholder       ="Enter Composition Url" 
                                            type        ="text"
                                         
                                           
                                            name        ="composition1.compositionUrl" 
                                            value       = {formik.values.composition1 && formik.values.composition1.compositionUrl}
                                            onChange    = {formik.handleChange}
                                            error       = {formik.errors.composition1 && formik.errors.composition1 && formik.touched.composition1 && formik.touched.composition1.compositionUrl && formik.errors.composition1.compositionUrl}
                                            helperText  = {formik.errors.composition1 && formik.errors.composition1 && formik.touched.composition1 && formik.touched.composition1.compositionUrl && formik.errors.composition1.compositionUrl ? formik.errors.composition1.compositionUrl : ''}
                                        />
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className='col-4'>
                                        <input 
                                            
                                            placeholder       ="Enter Composition Name" 
                                            type        ="text"
                                         
                                           
                                            name        ="composition2.compositionName" 
                                            value       = {formik.values.composition2 && formik.values.composition2.compositionName}
                                            onChange    = {formik.handleChange}
                                            error       = {formik.errors.composition2 && formik.touched.composition2 && formik.touched.composition2.compositionName && formik.errors.composition2.compositionName}
                                            helperText  = {formik.errors.composition2 && formik.touched.composition2 && formik.touched.composition2.compositionName && formik.errors.composition2.compositionName ? formik.errors.composition2.compositionName : ''}
                                        />
                                    </div>
                                    <div className='col-4'>
                                        <input 
                                            
                                            placeholder       ="Enter Composition Number" 
                                            type        ="text"
                                         
                                           
                                            name        ="composition2.compositionNumber" 
                                            value       = {formik.values.composition2 && formik.values.composition2.compositionNumber}
                                            onChange    = {formik.handleChange}
                                            error       = {formik.errors.composition2 && formik.touched.composition2 && formik.touched.composition2.compositionNumber && formik.errors.composition2.compositionNumber}
                                            helperText  = {formik.errors.composition2 && formik.touched.composition2 && formik.touched.composition2.compositionNumber && formik.errors.composition2.compositionNumber ? formik.errors.composition2.compositionNumber : ''}
                                        />
                                    </div>
                                    <div className='col-4'>
                                        <input 
                                            
                                            placeholder       ="Enter Composition Url" 
                                            type        ="text"
                                         
                                           
                                            name        ="composition2.compositionUrl" 
                                            value       = {formik.values.composition2 && formik.values.composition2.compositionUrl}
                                            onChange    = {formik.handleChange}
                                            error       = {formik.errors.composition2 && formik.errors.composition2 && formik.touched.composition2 && formik.touched.composition2.compositionUrl && formik.errors.composition2.compositionUrl}
                                            helperText  = {formik.errors.composition2 && formik.errors.composition2 && formik.touched.composition2 && formik.touched.composition2.compositionUrl && formik.errors.composition2.compositionUrl ? formik.errors.composition2.compositionUrl : ''}
                                        />
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className='col-4'>
                                        <input 
                                            
                                            placeholder       ="Enter Composition Name" 
                                            type        ="text"
                                         
                                           
                                            name        ="composition3.compositionName" 
                                            value       = {formik.values.composition3 && formik.values.composition3.compositionName}
                                            onChange    = {formik.handleChange}
                                            error       = {formik.errors.composition3 && formik.touched.composition3 && formik.touched.composition3.compositionName && formik.errors.composition3.compositionName}
                                            helperText  = {formik.errors.composition3 && formik.touched.composition3 && formik.touched.composition3.compositionName && formik.errors.composition3.compositionName ? formik.errors.composition3.compositionName : ''}
                                        />
                                    </div>
                                    <div className='col-4'>
                                        <input 
                                            
                                            placeholder       ="Enter Composition Number" 
                                            type        ="text"
                                         
                                           
                                            name        ="composition3.compositionNumber" 
                                            value       = {formik.values.composition3 && formik.values.composition3.compositionNumber}
                                            onChange    = {formik.handleChange}
                                            error       = {formik.errors.composition3 && formik.touched.composition3 && formik.touched.composition3.compositionNumber && formik.errors.composition3.compositionNumber}
                                            helperText  = {formik.errors.composition3 && formik.touched.composition3 && formik.touched.composition3.compositionNumber && formik.errors.composition3.compositionNumber ? formik.errors.composition3.compositionNumber : ''}
                                        />
                                    </div>
                                    <div className='col-4'>
                                        <input 
                                            
                                            placeholder       ="Enter Composition Url" 
                                            type        ="text"
                                         
                                           
                                            name        ="composition3.compositionUrl" 
                                            value       = {formik.values.composition3 && formik.values.composition3.compositionUrl}
                                            onChange    = {formik.handleChange}
                                            error       = {formik.errors.composition3 && formik.errors.composition3 && formik.touched.composition3 && formik.touched.composition3.compositionUrl && formik.errors.composition3.compositionUrl}
                                            helperText  = {formik.errors.composition3 && formik.errors.composition3 && formik.touched.composition3 && formik.touched.composition3.compositionUrl && formik.errors.composition3.compositionUrl ? formik.errors.composition3.compositionUrl : ''}
                                        />
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className='col-4'>
                                        <input 
                                            
                                            placeholder       ="Enter Composition Name" 
                                            type        ="text"
                                         
                                           
                                            name        ="composition4.compositionName" 
                                            value       = {formik.values.composition4 && formik.values.composition4.compositionName}
                                            onChange    = {formik.handleChange}
                                            error       = {formik.errors.composition4 && formik.touched.composition4 && formik.touched.composition4.compositionName && formik.errors.composition4.compositionName}
                                            helperText  = {formik.errors.composition4 && formik.touched.composition4 && formik.touched.composition4.compositionName && formik.errors.composition4.compositionName ? formik.errors.composition4.compositionName : ''}
                                        />
                                    </div>
                                    <div className='col-4'>
                                        <input 
                                            
                                            placeholder       ="Enter Composition Number" 
                                            type        ="text"
                                         
                                           
                                            name        ="composition4.compositionNumber" 
                                            value       = {formik.values.composition4 && formik.values.composition4.compositionNumber}
                                            onChange    = {formik.handleChange}
                                            error       = {formik.errors.composition4 && formik.touched.composition4 && formik.touched.composition4.compositionNumber && formik.errors.composition4.compositionNumber}
                                            helperText  = {formik.errors.composition4 && formik.touched.composition4 && formik.touched.composition4.compositionNumber && formik.errors.composition4.compositionNumber ? formik.errors.composition4.compositionNumber : ''}
                                        />
                                    </div>
                                    <div className='col-4'>
                                        <input 
                                            
                                            placeholder       ="Enter Composition Url" 
                                            type        ="text"
                                         
                                           
                                            name        ="composition4.compositionUrl" 
                                            value       = {formik.values.composition4 && formik.values.composition4.compositionUrl}
                                            onChange    = {formik.handleChange}
                                            error       = {formik.errors.composition4 && formik.errors.composition4 && formik.touched.composition4 && formik.touched.composition4.compositionUrl && formik.errors.composition4.compositionUrl}
                                            helperText  = {formik.errors.composition4 && formik.errors.composition4 && formik.touched.composition4 && formik.touched.composition4.compositionUrl && formik.errors.composition4.compositionUrl ? formik.errors.composition4.compositionUrl : ''}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='mt-5  h3'>Certification</div>

                        <div className='mt-3 row'>
                            <div className='col-12'>
                                <div className='row'>
                                    <div className='col-1  mt-2'>
                                        <input type="file" onChange={(e)=>saveFile(e,'certification1')} />
                                    </div>
                                    <div className='col-2 text-center'>
                                        <img id='certification1' src={formik.values.certification1 && formik.values.certification1.certificationImage ? formik.values.certification1.certificationImage : 'http://placehold.jp/48x48.png' } width="48" height="48"/> 
                                    </div>
                                    <div className='col-2'>
                                        <input 
                                            
                                            placeholder       ="Enter Certification Name" 
                                            type        ="text"
                                         
                                           
                                            name        ="certification1.certificationName" 
                                            value       = {formik.values.certification1 && formik.values.certification1.certificationName}
                                            onChange    = {formik.handleChange}
                                            error       = {formik.errors.certification1 && formik.touched.certification1 && formik.touched.certification1.certificationName && formik.errors.certification1.certificationName}
                                            helperText  = {formik.errors.certification1 && formik.touched.certification1 && formik.touched.certification1.certificationName && formik.errors.certification1.certificationName ? formik.errors.certification1.certificationName : ''}
                                        />
                                    </div>
                                    <div className='col-4'>
                                        <input 
                                            
                                            placeholder       ="Enter Certification Sub text" 
                                            type        ="text"
                                         
                                           
                                            name        ="certification1.certificationSubtext" 
                                            value       = {formik.values.certification1 && formik.values.certification1.certificationSubtext}
                                            onChange    = {formik.handleChange}
                                            error       = {formik.errors.certification1 && formik.touched.certification1 && formik.touched.certification1.certificationSubtext && formik.errors.certification1.certificationSubtext}
                                            helperText  = {formik.errors.certification1 && formik.touched.certification1 && formik.touched.certification1.certificationSubtext && formik.errors.certification1.certificationSubtext ? formik.errors.certification1.certificationSubtext : ''}
                                        />
                                    </div>
                                    <div className='col-3'>
                                        <input 
                                            
                                            placeholder       ="Enter Certification Link" 
                                            type        ="text"
                                         
                                           
                                            name        ="certification1.certificationLink" 
                                            value       = {formik.values.certification1 && formik.values.certification1.certificationLink}
                                            onChange    = {formik.handleChange}
                                            error       = {formik.errors.certification1 && formik.touched.certification1 && formik.touched.certification1.certificationLink && formik.errors.certification1.certificationLink}
                                            helperText  = {formik.errors.certification1 && formik.touched.certification1 && formik.touched.certification1.certificationLink && formik.errors.certification1.certificationLink ? formik.errors.certification1.certificationLink : ''}
                                        />
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className='col-1  mt-2'>
                                        <input type="file" onChange={(e)=>saveFile(e,'certification2')} />
                                    </div>
                                    <div className='col-2 text-center'>
                                        <img id='certification2' src={formik.values.certification2 && formik.values.certification2.certificationImage ? formik.values.certification2.certificationImage : 'http://placehold.jp/48x48.png' } width="48" height="48"/> 
                                    </div>
                                    <div className='col-2'>
                                        <input 
                                            
                                            placeholder       ="Enter Certification Name" 
                                            type        ="text"
                                         
                                           
                                            name        ="certification2.certificationName" 
                                            value       = {formik.values.certification2 && formik.values.certification2.certificationName}
                                            onChange    = {formik.handleChange}
                                            error       = {formik.errors.certification2 && formik.touched.certification2 && formik.touched.certification2.certificationName && formik.errors.certification2.certificationName}
                                            helperText  = {formik.errors.certification2 && formik.touched.certification2 && formik.touched.certification2.certificationName && formik.errors.certification2.certificationName ? formik.errors.certification2.certificationName : ''}
                                        />
                                    </div>
                                    <div className='col-4'>
                                        <input 
                                            
                                            placeholder       ="Enter Certification Sub text" 
                                            type        ="text"
                                         
                                           
                                            name        ="certification2.certificationSubtext" 
                                            value       = {formik.values.certification2 && formik.values.certification2.certificationSubtext}
                                            onChange    = {formik.handleChange}
                                            error       = {formik.errors.certification2 && formik.touched.certification2 && formik.touched.certification2.certificationSubtext && formik.errors.certification2.certificationSubtext}
                                            helperText  = {formik.errors.certification2 && formik.touched.certification2 && formik.touched.certification2.certificationSubtext && formik.errors.certification2.certificationSubtext ? formik.errors.certification2.certificationSubtext : ''}
                                        />
                                    </div>
                                    <div className='col-3'>
                                        <input 
                                            
                                            placeholder       ="Enter Certification Link" 
                                            type        ="text"
                                         
                                           
                                            name        ="certification2.certificationLink" 
                                            value       = {formik.values.certification2 && formik.values.certification2.certificationLink}
                                            onChange    = {formik.handleChange}
                                            error       = {formik.errors.certification2 && formik.touched.certification2 && formik.touched.certification2.certificationLink && formik.errors.certification2.certificationLink}
                                            helperText  = {formik.errors.certification2 && formik.touched.certification2 && formik.touched.certification2.certificationLink && formik.errors.certification2.certificationLink ? formik.errors.certification2.certificationLink : ''}
                                        />
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className='col-1  mt-2'>
                                        <input type="file" onChange={(e)=>saveFile(e,'certification3')} />
                                    </div>
                                    <div className='col-2 text-center'>
                                        <img id='certification3' src={formik.values.certification3 && formik.values.certification3.certificationImage ? formik.values.certification3.certificationImage : 'http://placehold.jp/48x48.png' } width="48" height="48"/> 
                                    </div>
                                    <div className='col-2'>
                                        <input 
                                            
                                            placeholder       ="Enter Certification Name" 
                                            type        ="text"
                                         
                                           
                                            name        ="certification3.certificationName" 
                                            value       = {formik.values.certification3 && formik.values.certification3.certificationName}
                                            onChange    = {formik.handleChange}
                                            error       = {formik.errors.certification3 && formik.touched.certification3 && formik.touched.certification3.certificationName && formik.errors.certification3.certificationName}
                                            // helperText  = {formik.errors.certification3 && formik.touched.certification3 && formik.touched.certification3.certificationName && formik.errors.certification3.certificationName ? formik.errors.certification3.certificationName : ''}
                                        />
                                    </div>
                                    <div className='col-4'>
                                        <input 
                                            
                                            placeholder       ="Enter Certification Sub text" 
                                            type        ="text"
                                         
                                           
                                            name        ="certification3.certificationSubtext" 
                                            value       = {formik.values.certification3 && formik.values.certification3.certificationSubtext}
                                            onChange    = {formik.handleChange}
                                            error       = {formik.errors.certification3 && formik.touched.certification3 && formik.touched.certification3.certificationSubtext && formik.errors.certification3.certificationSubtext}
                                            helperText  = {formik.errors.certification3 && formik.touched.certification3 && formik.touched.certification3.certificationSubtext && formik.errors.certification3.certificationSubtext ? formik.errors.certification3.certificationSubtext : ''}
                                        />
                                    </div>
                                    <div className='col-3'>
                                        <input 
                                            
                                            placeholder       ="Enter Certification Link" 
                                            type        ="text"
                                         
                                           
                                            name        ="certification3.certificationLink" 
                                            value       = {formik.values.certification3 && formik.values.certification3.certificationLink}
                                            onChange    = {formik.handleChange}
                                            error       = {formik.errors.certification3 && formik.touched.certification3 && formik.touched.certification3.certificationLink && formik.errors.certification3.certificationLink}
                                            helperText  = {formik.errors.certification3 && formik.touched.certification3 && formik.touched.certification3.certificationLink && formik.errors.certification3.certificationLink ? formik.errors.certification3.certificationLink : ''}
                                        />
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className='col-1  mt-2'>
                                        <input type="file" onChange={(e)=>saveFile(e,'certification4')} />
                                    </div>
                                    <div className='col-2 text-center'>
                                        <img id='certification4' src={formik.values.certification4 && formik.values.certification4.certificationImage ? formik.values.certification4.certificationImage : 'http://placehold.jp/48x48.png' } width="48" height="48"/> 
                                    </div>
                                    <div className='col-2'>
                                        <input 
                                            
                                            placeholder       ="Enter Certification Name" 
                                            type        ="text"
                                         
                                           
                                            name        ="certification4.certificationName" 
                                            value       = {formik.values.certification4 && formik.values.certification4.certificationName}
                                            onChange    = {formik.handleChange}
                                            error       = {formik.errors.certification4 && formik.touched.certification4 && formik.touched.certification4.certificationName && formik.errors.certification4.certificationName}
                                            // helperText  = {formik.errors.certification4 && formik.touched.certification4 && formik.touched.certification4.certificationName && formik.errors.certification4.certificationName ? formik.errors.certification4.certificationName : ''}
                                        />
                                    </div>
                                    <div className='col-4'>
                                        <input 
                                            
                                            placeholder       ="Enter Certification Sub text" 
                                            type        ="text"
                                         
                                           
                                            name        ="certification4.certificationSubtext" 
                                            value       = {formik.values.certification4 && formik.values.certification4.certificationSubtext}
                                            onChange    = {formik.handleChange}
                                            error       = {formik.errors.certification4 && formik.touched.certification4 && formik.touched.certification4.certificationSubtext && formik.errors.certification4.certificationSubtext}
                                            // helperText  = {formik.errors.certification4 && formik.touched.certification4 && formik.touched.certification4.certificationSubtext && formik.errors.certification4.certificationSubtext ? formik.errors.certification4.certificationSubtext : ''}
                                        />
                                    </div>
                                    <div className='col-3'>
                                        <input 
                                            
                                            placeholder       ="Enter Certification Link" 
                                            type        ="text"
                                         
                                           
                                            name        ="certification4.certificationLink" 
                                            value       = {formik.values.certification4 && formik.values.certification4.certificationLink}
                                            onChange    = {formik.handleChange}
                                            error       = {formik.errors.certification4 && formik.touched.certification4 && formik.touched.certification4.certificationLink && formik.errors.certification4.certificationLink}
                                            // helperText  = {formik.errors.certification4 && formik.touched.certification4 && formik.touched.certification4.certificationLink && formik.errors.certification4.certificationLink ? formik.errors.certification4.certificationLink : ''}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className='mt-5  h3'>Sustainable Swaps</div>

                        <div className='mt-3 row mb-3'>
                            <div className='col-12'>
                                    <div className='row'>
                                        <div className='col-1  mt-2'>
                                            <input type="file" onChange={(e)=>saveFile(e,'swap1')} />
                                        </div>
                                        <div className='col-2 text-center'>
                                            <img id='swap1' src={formik.values.link1 && formik.values.link1.linkImage ? formik.values.link1.linkImage : 'http://placehold.jp/48x48.png' } width="48" height="48"/> 
                                        </div>
                                        <div className='col-2'>
                                            <input 
                                                
                                                placeholder       ="Enter Swap Name" 
                                                type        ="text"
                                             
                                               
                                                name        ="link1.linkName" 
                                                value       = {formik.values.link1 && formik.values.link1.linkName}
                                                onChange    = {formik.handleChange}
                                                error       = {formik.errors.link1 && formik.touched.link1 && formik.touched.link1.linkName && formik.errors.link1.linkName}
                                                helperText  = {formik.errors.link1 && formik.touched.link1 && formik.touched.link1.linkName && formik.errors.link1.linkName ? formik.errors.link1.linkName : ''}
                                            />
                                        </div>
                                        <div className='col-4'>
                                            <input 
                                                
                                                placeholder       ="Enter Link Url" 
                                                type        ="text"
                                             
                                               
                                                name        ="link1.linkUrl" 
                                                value       = {formik.values.link1 && formik.values.link1.linkUrl}
                                                onChange    = {formik.handleChange}
                                                error       = {formik.errors.link1 && formik.errors.link1 && formik.touched.link1 && formik.touched.link1.linkUrl && formik.errors.link1.linkUrl}
                                                helperText  = {formik.errors.link1 && formik.errors.link1 && formik.touched.link1 && formik.touched.link1.linkUrl && formik.errors.link1.linkUrl ? formik.errors.link1.linkUrl : ''}
                                            />
                                        </div>
                                        <div className='col-3'>
                                            <input 
                                                
                                                placeholder       ="Enter Swap SubText" 
                                                type        ="text"
                                             
                                               
                                                name        ="link1.linkSubText" 
                                                value       = {formik.values.link1 && formik.values.link1.linkSubText}
                                                onChange    = {formik.handleChange}
                                                error       = {formik.errors.link1 && formik.errors.link1 && formik.touched.link1 && formik.touched.link1.linkSubText && formik.errors.link1.linkSubText}
                                                helperText  = {formik.errors.link1 && formik.errors.link1 && formik.touched.link1 && formik.touched.link1.linkSubText && formik.errors.link1.linkSubText ? formik.errors.link1.linkSubText : ''}
                                            />
                                        </div>
                                    </div>
                                    <div className='row mt-3'>
                                        <div className='col-1  mt-2'>
                                            <input type="file" onChange={(e)=>saveFile(e,'swap2')} />
                                        </div>
                                        <div className='col-2 text-center'>
                                            <img id='swap2' src={formik.values.link2 && formik.values.link2.linkImage ? formik.values.link2.linkImage : 'http://placehold.jp/48x48.png' } width="48" height="48"/> 
                                        </div>
                                        <div className='col-2'>
                                            <input 
                                                
                                                placeholder       ="Enter Swap Name" 
                                                type        ="text"
                                             
                                               
                                                name        ="link2.linkName" 
                                                value       = {formik.values.link2 && formik.values.link2.linkName}
                                                onChange    = {formik.handleChange}
                                                error       = {formik.errors.link2 && formik.touched.link2 && formik.touched.link2.linkName && formik.errors.link2.linkName}
                                                helperText  = {formik.errors.link2 && formik.touched.link2 && formik.touched.link2.linkName && formik.errors.link2.linkName ? formik.errors.link2.linkName : ''}
                                            />
                                        </div>
                                        <div className='col-4'>
                                            <input 
                                                
                                                placeholder       ="Enter Link Url" 
                                                type        ="text"
                                             
                                               
                                                name        ="link2.linkUrl" 
                                                value       = {formik.values.link2 && formik.values.link2.linkUrl}
                                                onChange    = {formik.handleChange}
                                                error       = {formik.errors.link2 && formik.errors.link2 && formik.touched.link2 && formik.touched.link2.linkUrl && formik.errors.link2.linkUrl}
                                                helperText  = {formik.errors.link2 && formik.errors.link2 && formik.touched.link2 && formik.touched.link2.linkUrl && formik.errors.link2.linkUrl ? formik.errors.link2.linkUrl : ''}
                                            />
                                        </div>
                                        <div className='col-3'>
                                            <input 
                                                
                                                placeholder       ="Enter Swap SubText" 
                                                type        ="text"
                                             
                                               
                                                name        ="link2.linkSubText" 
                                                value       = {formik.values.link2 && formik.values.link2.linkSubText}
                                                onChange    = {formik.handleChange}
                                                error       = {formik.errors.link2 && formik.errors.link2 && formik.touched.link2 && formik.touched.link2.linkSubText && formik.errors.link2.linkSubText}
                                                helperText  = {formik.errors.link2 && formik.errors.link2 && formik.touched.link2 && formik.touched.link2.linkSubText && formik.errors.link2.linkSubText ? formik.errors.link2.linkSubText : ''}
                                            />
                                        </div>
                                    </div>
                                    <div className='row mt-3'>
                                        <div className='col-1  mt-2'>
                                            <input type="file" onChange={(e)=>saveFile(e,'swap3')} />
                                        </div>
                                        <div className='col-2 text-center'>
                                            <img id='swap3' src={formik.values.link3 && formik.values.link3.linkImage ? formik.values.link3.linkImage : 'http://placehold.jp/48x48.png' } width="48" height="48"/> 
                                        </div>
                                        <div className='col-2'>
                                            <input 
                                                
                                                placeholder       ="Enter Swap Name" 
                                                type        ="text"
                                             
                                               
                                                name        ="link3.linkName" 
                                                value       = {formik.values.link3 && formik.values.link3.linkName}
                                                onChange    = {formik.handleChange}
                                                error       = {formik.errors.link3 && formik.touched.link3 && formik.touched.link3.linkName && formik.errors.link3.linkName}
                                                helperText  = {formik.errors.link3 && formik.touched.link3 && formik.touched.link3.linkName && formik.errors.link3.linkName ? formik.errors.link3.linkName : ''}
                                            />
                                        </div>
                                        <div className='col-4'>
                                            <input 
                                                
                                                placeholder       ="Enter Link Url" 
                                                type        ="text"
                                             
                                               
                                                name        ="link3.linkUrl" 
                                                value       = {formik.values.link3 && formik.values.link3.linkUrl}
                                                onChange    = {formik.handleChange}
                                                error       = {formik.errors.link3 && formik.touched.link3 && formik.touched.link3.linkUrl && formik.errors.link3.linkUrl}
                                                helperText  = {formik.errors.link3 && formik.errors.link3 && formik.touched.link3 && formik.touched.link3.linkUrl && formik.errors.link3.linkUrl ? formik.errors.link3.linkUrl : ''}
                                            />
                                        </div>
                                        <div className='col-3'>
                                            <input 
                                                
                                                placeholder       ="Enter Swap SubText" 
                                                type        ="text"
                                             
                                               
                                                name        ="link3.linkSubText" 
                                                value       = {formik.values.link3 && formik.values.link3.linkSubText}
                                                onChange    = {formik.handleChange}
                                                error       = {formik.errors.link3 && formik.errors.link3 && formik.touched.link3 && formik.touched.link3.linkSubText && formik.errors.link3.linkSubText}
                                                helperText  = {formik.errors.link3 && formik.errors.link3 && formik.touched.link3 && formik.touched.link3.linkSubText && formik.errors.link3.linkSubText ? formik.errors.link3.linkSubText : ''}
                                            />
                                        </div>
                                    </div>
                                    <div className='row mt-3'>
                                        <div className='col-1 mt-2 '>
                                            <input type="file" onChange={(e)=>saveFile(e,'swap4')} />
                                        </div>
                                        <div className='col-2 text-center'>
                                            <img id='swap4' src={formik.values.link4 && formik.values.link4.linkImage ? formik.values.link4.linkImage : 'http://placehold.jp/48x48.png' } width="48" height="48"/> 
                                        </div>
                                        <div className='col-2'>
                                            <input 
                                                
                                                placeholder       ="Enter Swap Name" 
                                                type        ="text"
                                             
                                               
                                                name        ="link4.linkName" 
                                                value       = {formik.values.link4 && formik.values.link4.linkName}
                                                onChange    = {formik.handleChange}
                                                error       = {formik.errors.link4 && formik.touched.link4 && formik.touched.link4.linkName && formik.errors.link4.linkName}
                                                // helperText  = {formik.errors.link4 && formik.errors.link4 && formik.touched.link4 && formik.touched.link4.linkName && formik.errors.link4.linkName ? formik.errors.link4.linkName : ''}
                                        />
                                        </div>
                                        <div className='col-4'>
                                            <input 
                                                
                                                placeholder       ="Enter Link Url" 
                                                type        ="text"
                                             
                                               
                                                name        ="link4.linkUrl" 
                                                value       = {formik.values.link4 && formik.values.link4.linkUrl}
                                                onChange    = {formik.handleChange}
                                                error       = {formik.errors.link4 && formik.touched.link4 && formik.touched.link4.linkUrl && formik.errors.link4.linkUrl}
                                                helperText  = {formik.errors.link4 && formik.errors.link4 && formik.touched.link4 && formik.touched.link4.linkUrl && formik.errors.link4.linkUrl ? formik.errors.link4.linkUrl : ''}
                                            />
                                        </div>
                                        <div className='col-3'>
                                            <input 
                                                
                                                placeholder       ="Enter Swap SubText" 
                                                type        ="text"
                                             
                                               
                                                name        ="link4.linkSubText" 
                                                value       = {formik.values.link4 && formik.values.link4.linkSubText}
                                                onChange    = {formik.handleChange}
                                                error       = {formik.errors.link4 && formik.errors.link4 && formik.touched.link4 && formik.touched.link4.linkSubText && formik.errors.link4.linkSubText}
                                                helperText  = {formik.errors.link4 && formik.errors.link4 && formik.touched.link4 && formik.touched.link4.linkSubText && formik.errors.link4.linkSubText ? formik.errors.link4.linkSubText : ''}
                                            />
                                        </div>
                                    </div>
                            </div>
                        </div>
                        
                    </div>

                        <div className=' mt-2'>
                            <ThemeProvider theme={theme}>
                                <Button 
                                    style={{textTransform: 'none'}} 
                                    className   ='btn'
                                    variant     ="contained"
                                    fullWidth   ={true}
                                    color       ='primary'
                                    type        ="submit"
                                    startIcon   = {state.loader && <CircularProgress size={20} color={'inherit'}/>}
                                >{(formik.values.edit) ? 'Update Product' : 'Add Product'}</Button>
                            </ThemeProvider>    
                        </div>
                        
                        <div className='error'>{state.apiError}</div>
                </div>
            </div>
        </form>
    </div>
  )
}
