const PngIcons = {
    logo        : '/images/logo.png',
    loader      : '/images/loader.gif',

    brand       : '/images/brand.png',
    product     : '/images/product.png',
    user        : '/images/user.png',

    certificate         : '/images/certificate.png',
    composition         : '/images/composition.png',
    link                : '/images/link.png',

    hide                : '/images/hide.png',
    view                : '/images/view.png',

    toggle              : '/images/menu.png',
}




export default PngIcons