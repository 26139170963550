import React, { useEffect, useState, useContext } from 'react'
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import { ProductService } from '../../services';
import localforage from 'localforage';
import { UserContext } from '../../context/user.context';
import CSV from './Csv';
import Loader from '../../components/Loader';
import { CSVLink } from "react-csv";
import Table from '../../components/Table.tsx';
import { createTheme } from '@material-ui/core';
import { ThemeProvider } from '@emotion/react';

let headers = [
  { label: "_id", key: "_id"},
  { label: "brand", key: "brand" },
  { label: "product", key: "product" },
  { label: "certificationname1", key: "certificationname1" },
  { label: "certificationimage1", key: "certificationimage1"},
  { label: "certificationsubtext1", key: "certificationsubtext1" },
  { label: "certificationlink1", key: "certificationlink1" },
  { label: "certificationname2", key: "certificationname2" },
  { label: "certificationimage2", key: "certificationimage2"},
  { label: "certificationsubtext2", key: "certificationsubtext2" },
  { label: "certificationlink2", key: "certificationlink2" },
  { label: "certificationname3", key: "certificationname3" },
  { label: "certificationimage3", key: "certificationimage3"},
  { label: "certificationsubtext3", key: "certificationsubtext3" },
  { label: "certificationlink3", key: "certificationlink3" },
  { label: "certificationname4", key: "certificationname4" },
  { label: "certificationimage4", key: "certificationimage4"},
  { label: "certificationsubtext4", key: "certificationsubtext4" },
  { label: "certificationlink4", key: "certificationlink4" },
  { label: "compositionname1", key: "compositionname1" },
  { label: "compositionnumber1", key: "compositionnumber1" },
  { label: "compositionurl1", key: "compositionurl1" },
  { label: "compositionname2", key: "compositionname2" },
  { label: "compositionnumber2", key: "compositionnumber2" },
  { label: "compositionurl2", key: "compositionurl2" },
  { label: "compositionname3", key: "compositionname3" },
  { label: "compositionnumber3", key: "compositionnumber3" },
  { label: "compositionurl3", key: "compositionurl3" },
  { label: "compositionname4", key: "compositionname4" },
  { label: "compositionnumber4", key: "compositionnumber4" },
  { label: "compositionurl4", key: "compositionurl4" },
  { label: "linkname1", key: "linkname1" },
  { label: "linkimage1", key: "linkimage1" },
  { label: "link1", key: "link1" },
  { label: "linksubtext1", key: "linksubtext1" },
  { label: "linkname2", key: "linkname2" },
  { label: "linkimage2", key: "linkimage2" },
  { label: "link2", key: "link2" },
  { label: "linksubtext2", key: "linksubtext2" },
  { label: "linkname3", key: "linkname3" },
  { label: "linkimage3", key: "linkimage3" },
  { label: "link3", key: "link3" },
  { label: "linksubtext3", key: "linksubtext3" },
  { label: "linkname4", key: "linkname4" },
  { label: "linkimage4", key: "linkimage4" },
  { label: "link4", key: "link4" },
  { label: "linksubtext4", key: "linksubtext4" },
];


export default function Composition() {

  const history = useHistory();
  const [products, setProducts] = useState();
  const [loader, setLoader] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const user = useContext(UserContext);

  const getImage = (item) => {
    if(item && (item.certificationName || item.linkName))
    {
      let name = item.certificationName || item.linkName
      if(name.replace(/\s+/g, '').toLowerCase().includes('fairtrade')) return 'https://sustie.s3.eu-north-1.amazonaws.com/fairtrade.png'
      else if(name.replace(/\s+/g, '').toLowerCase().includes('finn')) return 'https://sustie.s3.eu-north-1.amazonaws.com/finn.png'
      else if(name.replace(/\s+/g, '').toLowerCase().includes('tise')) return 'https://sustie.s3.eu-north-1.amazonaws.com/tise.png'
      else if(name.replace(/\s+/g, '').toLowerCase().includes('fjong')) return 'https://sustie.s3.eu-north-1.amazonaws.com/fjong.png'
      else if(name.replace(/\s+/g, '').toLowerCase().includes('holzweiler')) return 'https://sustie.s3.eu-north-1.amazonaws.com/holzweiler.png'
      else if(name.replace(/\s+/g, '').toLowerCase().includes('nordicswan')) return 'https://sustie.s3.eu-north-1.amazonaws.com/nordicswan.png'
      else if(name.replace(/\s+/g, '').toLowerCase().includes('oeko')) return 'https://sustie.s3.eu-north-1.amazonaws.com/oeko.png'
      else if(name.replace(/\s+/g, '').toLowerCase().includes('newmovement')) return 'https://sustie.s3.eu-north-1.amazonaws.com/newmovement.png'
      else if(name.replace(/\s+/g, '').toLowerCase().includes('gots')) return 'https://sustie.s3.eu-north-1.amazonaws.com/gots.png'
      else return ''
    }
  } 



  useEffect(async ()=>{
    setLoader(true);
    const {response, error} = await ProductService.Get();
    if(response) {
      let productData = [];
      for (const element of response.data) {
        productData.push({
          ...element,
          action :  <div><img src='/images/delete-button.png' className='cp' onClick={()=>{handleDelete(element._id)}} height={24} width={24}/>&nbsp;&nbsp;&nbsp;&nbsp;
                        <img src='/images/edit.png' className='cp' onClick={() => {history.push({pathname: '/dashboard/addproduct', state: {product: {...element, edit: true}}})}}  height={24} width={24}/></div>
        })
      }
      setProducts(productData);
      let array = [];
      for (const element of response.data) {
          array.push({
            _id  : element._id,
            brand : element.brandName, 
            product : element.productName,
            certificationname1 : element.certifications[0] && element.certifications[0].certificationName ? element.certifications[0].certificationName : '',
            certificationimage1 : element.certifications[0] && element.certifications[0].certificationImage ? element.certifications[0].certificationImage : getImage(element.certifications[0]),
            certificationsubtext1 : element.certifications[0] && element.certifications[0].certificationSubtext ? element.certifications[0].certificationSubtext : '',
            certificationlink1 : element.certifications[0] && element.certifications[0].certificationLink ? element.certifications[0].certificationLink : '',
            certificationname2 : element.certifications[1] && element.certifications[1].certificationName ? element.certifications[1].certificationName : '',
            certificationimage2 : element.certifications[1] && element.certifications[1].certificationImage ? element.certifications[1].certificationImage : getImage(element.certifications[1]),
            certificationsubtext2 : element.certifications[1] && element.certifications[1].certificationSubtext ? element.certifications[1].certificationSubtext : '',
            certificationlink2 : element.certifications[1] && element.certifications[1].certificationLink ? element.certifications[1].certificationLink : '',
            certificationname3 : element.certifications[2] && element.certifications[2].certificationName ? element.certifications[2].certificationName : '',
            certificationimage3 : element.certifications[2] && element.certifications[2].certificationImage ? element.certifications[2].certificationImage : getImage(element.certifications[2]),
            certificationsubtext3 : element.certifications[2] && element.certifications[2].certificationSubtext ? element.certifications[2].certificationSubtext : '',
            certificationlink3 : element.certifications[2] && element.certifications[2].certificationLink ? element.certifications[2].certificationLink : '',
            certificationname4 : element.certifications[3] && element.certifications[3].certificationName ? element.certifications[3].certificationName : '',
            certificationimage4 : element.certifications[3] && element.certifications[3].certificationImage ? element.certifications[3].certificationImage : getImage(element.certifications[3]),
            certificationsubtext4 : element.certifications[3] && element.certifications[3].certificationSubtext ? element.certifications[3].certificationSubtext : '',
            certificationlink4 : element.certifications[3] && element.certifications[3].certificationLink ? element.certifications[3].certificationLink : '',
            
            compositionname1 : element.compositions[0] && element.compositions[0].compositionName ? element.compositions[0].compositionName : '',
            compositionnumber1 : element.compositions[0] && element.compositions[0].compositionNumber ? element.compositions[0].compositionNumber : '',
            compositionurl1  : element.compositions[0] && element.compositions[0].compositionUrl ? element.compositions[0].compositionUrl : '',
            compositionname2 : element.compositions[1] && element.compositions[1].compositionName ? element.compositions[1].compositionName : '',
            compositionnumber2 : element.compositions[1] && element.compositions[1].compositionNumber ? element.compositions[1].compositionNumber : '',
            compositionurl2  : element.compositions[1] && element.compositions[1].compositionUrl ? element.compositions[1].compositionUrl : '',
            compositionname3 : element.compositions[2] && element.compositions[2].compositionName ? element.compositions[2].compositionName : '',
            compositionnumber3 : element.compositions[2] && element.compositions[2].compositionNumber ? element.compositions[2].compositionNumber : '',
            compositionurl3  : element.compositions[2] && element.compositions[2].compositionUrl ? element.compositions[2].compositionUrl : '',
            compositionname4 : element.compositions[3] && element.compositions[3].compositionName ? element.compositions[3].compositionName : '',
            compositionnumber4 : element.compositions[3] && element.compositions[3].compositionNumber ? element.compositions[3].compositionNumber : '',
            compositionurl4  : element.compositions[3] && element.compositions[3].compositionUrl ? element.compositions[3].compositionUrl : '',
           
            linkname1 : element.links[0] && element.links[0].linkName ? element.links[0].linkName : '',
            linkimage1 : element.links[0] && element.links[0].linkImage ? element.links[0].linkImage : getImage(element.links[0]),
            link1 : element.links[0] && element.links[0].linkUrl ? element.links[0].linkUrl : '',
            linksubtext1: element.links[0] && element.links[0].linkSubText ? element.links[0].linkSubText : '',
            linkname2 : element.links[1] && element.links[1].linkName ? element.links[1].linkName : '',
            linkimage2 : element.links[1] && element.links[1].linkImage ? element.links[1].linkImage : getImage(element.links[1]),
            link2 : element.links[1] && element.links[1].linkUrl ? element.links[1].linkUrl : '',
            linksubtext2: element.links[1] && element.links[1].linkSubText ? element.links[1].linkSubText : '',
            linkname3 : element.links[2] && element.links[2].linkName ? element.links[2].linkName : '',
            linkimage3 : element.links[2] && element.links[2].linkImage ? element.links[2].linkImage : getImage(element.links[2]),
            link3 : element.links[2] && element.links[2].linkUrl ? element.links[2].linkUrl : '',
            linksubtext3: element.links[2] && element.links[2].linkSubText ? element.links[2].linkSubText : '',
            linkname4 : element.links[3] && element.links[3].linkName ? element.links[3].linkName : '',
            linkimage4 : element.links[3] && element.links[3].linkImage ? element.links[3].linkImage : getImage(element.links[3]),
            link4 : element.links[3] && element.links[3].linkUrl ? element.links[3].linkUrl : '',
            linksubtext4: element.links[3] && element.links[3].linkSubText ? element.links[3].linkSubText : '',

          })
      }
      setCsvData(array);
    }
    setLoader(false);
  },[])

   
  const columns = [
    {
        Header: 'Brand Name',
        accessor: 'brandName',
        sort: true,
    },
    {
        Header: 'Product Name',
        accessor: 'productName',
        sort: true,
    },
    {
        Header: 'Created At',
        accessor: 'created',
        sort: true,
    },
    {
        Header: 'Action',
        accessor: 'action',
        sort: true,
    }
  ];

  const sizePerPageList = [
    {
        text: '5',
        value: 5,
    },
    {
      text: '8',
      value: 8,
    },
    {
        text: '10',
        value: 10,
    },
    {
        text: '25',
        value: 25,
    },
    {
        text: 'All',
        value: products ? products.length : '',
    },
  ];

  
  const handleDelete = async (_id) => {
    const {response, error} = await ProductService.Delete({payload: {_id: _id}});
    window.location.reload();
  }

  const theme = createTheme({
    status: {
      danger: '#e53e3e',
    },
    palette: {
      primary: {
        main: '#3bafda',
        darker: '#3295b9',
        contrastText: '#fff',
      },
      neutral: {
        main: '#fff',
        contrastText: '#fff',
      },
    },
  });

  return (
    <div className='composition'>
      <div className='h4 sideheader'>Composition</div>
      {
          loader ? <Loader/> :
            <div className='content'>
              <div className='cardBack'>
              <CSV setLoader={setLoader}/>
                <ThemeProvider theme={theme}>
                  <Button 
                    style={{textTransform: 'none'}} 
                    className   ='mt-4 btn'
                    variant     ="contained"
                    color       ='primary'
                    fullWidth   = {true}
                      onClick     = {
                        ()=> history.push('/dashboard/addproduct')
                      }
                    >Add New Product</Button> 
                </ThemeProvider>

                { csvData &&csvData.length > 0 ? <div className='float-end csvData p-2 mt-2'>
                  <CSVLink data={csvData} headers={headers} >
                    Export Data
                  </CSVLink>
                </div> : ''}

                  {products && products.length > 0 ?
                      <div className='tableData'>
                        <Table
                          columns={columns}
                          data={products}
                          pageSize={8}
                          sizePerPageList={sizePerPageList}
                          isSortable={true}
                          pagination={true}
                          isSelectable={false}
                        />
                      </div>
                    : ''
                  }
              </div>
            </div>
      }
    </div>
  )
}
