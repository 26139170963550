import React, { useContext } from 'react'
import { UserContext } from '../../context/user.context';

export default function Profile() {
  const user = useContext(UserContext);
  
  return (
    
    <div className='profile'>
      <div className='h4 sideheader'>Profile</div>
        <div className='content'>
          <div className='cardBack'>
            <div className=' row'> 
                <div className='col-2 h3 avatar'>
                  Avatar
                </div>
                <div className='col-6'>
                  {
                    user.avatar ? <img src={user.avatar}></img>
                    : <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
                        <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                        <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
                      </svg>
                  }
                </div>
            </div>
            <div className='mt-4 row'> 
                <div className='col-2 h3'>
                  Profile Name
                </div>
                <div className='col-6'>
                  {user.fullName}
                </div>
            </div>
            <div className='mt-4 row'> 
                <div className='col-2 h3'>
                  Email
                </div>
                <div className='col-6'>
                  {user.email}
                </div>
            </div>
            <div className='mt-4 row'> 
                <div className='col-2 h3'>
                  User Type
                </div>
                <div className='col-6'>
                  {user.types.includes(2) ? 'Admin' : 'Normal User'}
                </div>
            </div>
          </div>
        </div>
    </div>
  )
}
