import localforage from 'localforage';
import React, { useEffect, useState } from 'react'
import { PngIcons } from '../icons'
import { utils } from '../utils'
import DropDown from './DropDown'

export default function Header(props) {
  const [user, setUser] = useState();

  useEffect(async ()=>{
    const user = await localforage.getItem('user');
    setUser(user)
  }, [user===null])

  return (
    <div className='header d-flex flex-row justify-content-between'>
       <img className='toggle' onClick={()=>{props.setShow(!props.show)}} src={PngIcons.toggle} width='24px' height='24px'/>

        <DropDown user={user}/>
      </div>



  
  )
}
