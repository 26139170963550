import * as yup from 'yup';

const ForgetPassword = yup.object({
    email    : yup.string().email().required(),
})

const ResetPassword = yup.object({
    password : yup.string().min(8).required()
})

const LoginVld = yup.object({
    email    : yup.string().email().required(),
    password : yup.string().min(8).required()
})

const SignupVld = yup.object({
    fullName : yup.string().required('This field is required'),
    email    : yup.string().email().required(),
    password : yup.string().min(8).required(),
    confirmPassword: yup.string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
})


const CreateUser  = yup.object({
    fullName : yup.string().required('This field is required'),
    email    : yup.string().email().required(),
    password : yup.string().min(8).required(),
    role     : yup.number()
})

export {
    LoginVld,
    SignupVld,
    ForgetPassword,
    ResetPassword,
    CreateUser
}