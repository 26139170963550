import React, { useState } from 'react'
import Header from '../../components/Header'
import { PngIcons } from '../../icons';
import Login from './Login';
import Signup from './Signup';

function Auth ({...props}) {

    const [title, setTitle] = useState();

    return (
        <div class='auth'>
            {/* <Header logo={true}/> */}
            <div className='flexDiv'>
                <div class='box'>
                    <div className='logo'> SUSTIE</div>
                    <div className='mt-3 mb-4 caption'>Enter your email address and password <br/> to access admin panel.</div>
                {
                    props.location.pathname.includes('signup') ? <Signup setTitle={setTitle}/> : <Login setTitle={setTitle}/>
                }
                </div>
            </div>
            <div className='position-absolute footer caption'>{new Date().getFullYear()} © Admin panel by <a href='https://www.sustie.io/'>Sustie.io</a></div>
        </div>
    )
}

export default Auth
