import React, { useContext, useEffect, useState } from 'react'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { AuthVld } from '../../validation'
import { CircularProgress } from '@mui/material';
import AuthService from '../../services/Auth';
import localforage from 'localforage';
import { UserContext } from '../../context/user.context';

export default function Signup({setTitle}) {

    const history = useHistory();
    const userContext = useContext(UserContext);

    const [state, setState] = useState({
        loader   : false,
        apiError : ''
      });
    
    const initState = {
        fullName        : '',
        email           : '',
        password        : '',
        confirmPassword : ''
    }

    const handleSubmit = async () => {
        setState({loader: true});
        
        let payload = {
            fullName : formik.values.fullName,
            email : formik.values.email,
            password : formik.values.password
        }

        const {error, response} = await AuthService.Signup({payload});
        if(error) return setState({apiError: error.error, loader: false});
        else {
            localforage.setItem('user', response.data)
            localforage.setItem('token',response.data.token)
            userContext.updateUser(response.data)
            history.push('/dashboard');
            setState({...state, loader: false});
        }
    }

    const formik = useFormik({
        initialValues    : { ...initState },
        onSubmit         : handleSubmit,
        validationSchema : AuthVld.SignupVld,
        isInitialValid   : false
    })
    

    useEffect(()=>{
        setTitle('Sign Up');
    },[])

  return (
    <div className='signup'>
        <form onSubmit={formik.handleSubmit}>
            <div>
                <TextField 
                    color       ='success' 
                    label       ="email" 
                    type        ="email"
                    variant     ="outlined" 
                    fullWidth   = {true}
                    name        ="email" 
                    value       = {formik.values.email}
                    onChange    = {formik.handleChange}
                    error       = {formik.touched.email && formik.errors.email}
                    helperText  = {formik.touched.email && formik.errors.email ? formik.errors.email : ''}
                />
            </div>
            <div className='mt-4'>
                <TextField 
                    color       ='success' 
                    label       ="full Name"
                    type        ="text" 
                    variant     ="outlined" 
                    fullWidth   = {true}
                    name        ="fullName" 
                    value       = {formik.values.fullName}
                    onChange    = {formik.handleChange}
                    error       = {formik.touched.fullName && formik.errors.fullName}
                    helperText  = {formik.touched.fullName && formik.errors.fullName ? formik.errors.fullName : ''}
                />
            </div>
            <div className='mt-4'>
                <TextField 
                    color       ='success' 
                    label       ="password" 
                    type        ="password"
                    variant     ="outlined" 
                    fullWidth   = {true}
                    name        = "password" 
                    value       = {formik.values.password}
                    onChange    = {formik.handleChange}
                    error       = {formik.touched.password && formik.errors.password}
                    helperText  = {formik.touched.password && formik.errors.password ? formik.errors.password : ''}
                />
            </div>
            <div className='mt-4'>
                <TextField 
                    color       ='success' 
                    label       ="confirm password" 
                    type        ="password"
                    variant     ="outlined" 
                    fullWidth   = {true}
                    name        ="confirmPassword" 
                    value       = {formik.values.confirmPassword}
                    onChange    = {formik.handleChange}
                    error       = {formik.touched.confirmPassword && formik.errors.confirmPassword}
                    helperText  = {formik.touched.confirmPassword && formik.errors.confirmPassword ? formik.errors.confirmPassword : ''}
                />
            </div>

            <div className='mt-4'>
                <Button 
                    variant     ="outlined"
                    fullWidth   ={true}
                    color       ='success'
                    type        ="submit"
                    startIcon   = {state.loader && <CircularProgress size={20} color={'inherit'}/>}

                >Sign up</Button>
            </div>

            <div className='mt-4 error'>{state.apiError}</div>
        </form>
        <div className='p2 mt-5'>Don’t have an account?  <span className='sh2 cp' onClick={()=>{history.push('/')}}>Login</span></div>
    </div>
  )
}
