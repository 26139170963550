import axios from 'axios'
import { Handlers } from '../utils'
import env from '../config'
import MainService from './Main'
const service  = '/v1/product'

const Create = async({payload}) => await axios.post(env.API_URL+service + '/addproduct', payload, {headers: await MainService.getTokenHeader()})
const Delete = async({payload}) => await axios.put(env.API_URL+service + '/deleteproduct', payload, {headers: await MainService.getTokenHeader()})
const Update = async({payload}) => await axios.put(env.API_URL+service + '/updateproduct', payload, {headers: await MainService.getTokenHeader()})
const Get    = async({query}) => await axios.get(env.API_URL+service + '/public/getallproduct', {headers: await MainService.getTokenHeader()})
const Upload = async({payload, desiredPath}) => await axios.post(env.API_URL+service + '/upload/image?desiredPath='+desiredPath, payload, {headers: await MainService.getTokenHeader()})


const ProductService = {
    Create      : Handlers.Services(Create),
    Get         : Handlers.Services(Get),
    Delete      : Handlers.Services(Delete),
    Update      : Handlers.Services(Update),
    Upload      : Handlers.Services(Upload)
}

export default ProductService
