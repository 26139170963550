import { useFormik } from 'formik';
import React, { useState } from 'react'
import TextField from '@mui/material/TextField';
import { AuthVld, ProductVld } from '../../validation';
import Button from '@mui/material/Button';
import { CircularProgress, createTheme } from '@mui/material';
import { AuthService, ProductService } from '../../services';
import { useHistory } from 'react-router-dom';
import localforage from 'localforage';
import { PngIcons } from '../../icons';
import { ThemeProvider } from '@emotion/react';

export default function AddUser(props) {

    const history = useHistory();
    const [hide, setHide] = useState(true);
    let user = history.location && history.location.state && history.location.state.user;

    const [state, setState] = useState({
        loader   : false,
        apiError : ''
      });
    
    const initState = {
        fullName            : user ? user.fullName : '',
        email               : user ? user.email : '',
        password            : user ? user.password : '',
        edit                : user && user.edit ? user.edit : false,
        _id                 : user && user._id ? user._id : ''
    }


    const theme = createTheme({
        status: {
          danger: '#e53e3e',
        },
        palette: {
          primary: {
            main: '#3bafda',
            darker: '#3295b9',
            contrastText: '#fff',
          },
          neutral: {
            main: '#fff',
            contrastText: '#fff',
          },
        },
      });


    const handleSubmit = async () => {
        setState({loader: true});
        
        let payload = {...formik.values}
        if(payload.edit)
        {
            delete payload.edit;
            payload.role = 3
            const {error , response} = await AuthService.Update({payload})
            if(error) return setState({apiError: error.error || error.message, loader: false})
        }
        else 
        {
            delete payload.edit;
            delete payload._id;
            payload.role = 3
            const {error , response} = await AuthService.Signup({payload})
            if(error) return setState({apiError: error.error || error.message, loader: false})
        }

        setState({...state, loader: false})
        return history.push('/dashboard/users');
  

    }

    const formik = useFormik({
        initialValues    : { ...initState },
        onSubmit         : handleSubmit,
        validationSchema : AuthVld.CreateUser,
        isInitialValid   : false
    })
  return (
    <div className='adduser'>
        <form onSubmit={formik.handleSubmit}>
            <div className='h4 sideheader'>Add User</div>
            <div className='content'>
                <div className='cardBack'>
                    <div className='row'>
                        <div className='col-5'>
                                <input 
                                    type        ="text"
                                    name        ="fullName" 
                                    placeholder = "Enter your fullName"
                                    value       = {formik.values.fullName}
                                    onChange    = {formik.handleChange}
                                    error       = {formik.touched.fullName && formik.errors.fullName}
                                    helperText  = {formik.touched.fullName && formik.errors.fullName ? formik.errors.fullName : ''}
                                />
                        </div>
                    </div> 

                    <div className='row mt-3'>
                        <div className='col-5'>
                            
                             <input 
                                type        ="email"
                                name        ="email" 
                                placeholder = "Enter your email"
                                value       = {formik.values.email}
                                onChange    = {formik.handleChange}
                                error       = {formik.touched.email && formik.errors.email}
                                helperText  = {formik.touched.email && formik.errors.email ? formik.errors.email : ''}
                            />
                        </div>
                    </div>
                    
                    {
                        (!formik.values.edit) ? <div className='row mt-3'>
                            <div className='col-5 position-relative'>    
                                <input 
                                    type        = { hide ? "password" : "text"}
                                    name        = "password" 
                                    placeholder = "Enter your password"
                                    value       = {formik.values.password}
                                    onChange    = {formik.handleChange}
                                    error       = {formik.touched.password && formik.errors.password}
                                    helperText  = {formik.touched.password && formik.errors.password ? formik.errors.password : ''}
                                />
                                <div className='password' onClick={()=>{setHide(!hide)}}>
                                    { hide ? <img src={PngIcons.view} width='18px' height='18px'/> : <img src={PngIcons.hide} width='18px' height='18px'/>}
                                </div>
                            </div>
                        </div> 
                    : ''}

                    <div className='row mt-3'>
                        <div className='col-5 '>
                        <ThemeProvider theme={theme}>
                            <Button 
                                style={{textTransform: 'none'}} 
                                className   ='btn'
                                variant     ="contained"
                                fullWidth   ={true}
                                color       ='primary'
                                type        ="submit"
                                startIcon   = {state.loader && <CircularProgress size={20} color={'inherit'}/>}
                            >{(formik.values.edit) ? 'Update User' : 'Add User'}</Button>
                        </ThemeProvider>
                        </div>
                    </div> 
                    {console.log(formik)}

                    <div className='error'>{state.apiError}</div>
                </div>
            </div>
        </form>
    </div>
  )
}
