import './assets/global.scss'
import React, { useContext, useEffect } from 'react'
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { UserContextComponent } from './context/user.context';

import { AuthGuard, CentralGuard} from './utils/RouteGuards';

//pages
import Auth from './pages/auth/Auth';
import Main from './pages/Main';


function App(props) {
  return (
    <UserContextComponent {...props}>
      <div className="App" style={{'overflow' : 'hidden'}}>
        <BrowserRouter>
            <Switch>
              <AuthGuard exact path="/" component={Auth} />
              <CentralGuard path="/dashboard" component={Main}/>
            </Switch>
        </BrowserRouter>
      </div>
    </UserContextComponent>

  );
}

export default App;
