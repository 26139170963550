import React from 'react'
import { PngIcons } from '../icons'

function Loader({width, baseWidth, height, baseHeight, className}) {

    setTimeout(()=>{
    },10000)

    return (
       <div className='d-flex flex-column justify-content-center align-items-center' style={{height: '58vh'}}>
           <img src={PngIcons.loader} width={120} height={120}></img>
           <div>Please wait a while to load</div>
       </div>
    )
}

export default Loader
