import * as localForage from 'localforage'
import ReactDOM from 'react-dom';
import CustomToasters from '../components/CustomToasters';


const Logout = async() => {
        localForage.clear().then(res => window.location.assign('/'))
}

const getRandomNumber = (length = 8) => {
    return Math.floor(Math.pow(10, length-1) + Math.random()*Math.pow(10, length-1))
}

const getQueryString = (query) => {
    return Object.entries(query).map(entry => entry[0]+'='+entry[1]).join('&')
}

const compareJSON = (objA, objB) => {
    return JSON.stringify(objA) === JSON.stringify(objB)
}

const emptyJSON = (json) => {
    return JSON.stringify(json) === '{}'
}

const showToaster = ({position, title, message, severity, delay}) => {
    const notifications = [{title, message, severity, progress: 0}]
    ReactDOM.render(<CustomToasters position={position} notifications={notifications} delay = {delay}/>, document.getElementById('Toaster'))
}

const getDate = (dateInMS, withTime=false) => {
    if(!dateInMS) return
    const timeOptions = {hour: '2-digit', minute: '2-digit'}
    return new Date(dateInMS).toLocaleDateString('en-US', {month: '2-digit', day: '2-digit', year:'numeric', ...(withTime && timeOptions)}).split('/').join('-')
}

const booleanHasAnyValue = (boolean) => {
    return typeof(boolean) === 'boolean'
}
const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

const capitalizeAll = (string) => {
      const Arr = string.split(" ")
      let result = []
      Arr.map((value, index)=>(
          result.push(value.charAt(0).toUpperCase() + value.slice(1))
      ))
    return result.join(' ')
  }

export {
    Logout,
    capitalizeAll,
    capitalize,
    getRandomNumber,
    getQueryString,
    compareJSON,
    emptyJSON,
    showToaster,
    getDate,
    booleanHasAnyValue,
}