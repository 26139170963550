import React, { useState } from 'react'
import { Switch } from 'react-router-dom'
import Header from '../components/Header'
import Sidebar from '../components/Sidebar'
import { User } from '../enums'
import { RoleGuard } from '../utils/RouteGuards'
import AddProduct from './composition/AddProduct'
import Composition from './composition/Composition'
import Dashboard from './dashboard/Dashboard'
import Profile from './profile/Profile'
import AddUser from './users/AddUser'
import Users from './users/Users'

export default function Main(props) {

  const [show, setShow] = useState(true);
  return (
    <div className='d-flex flex-row main'>
      <div className={show ? 'column1' : 'column1Partial'}>
        <Sidebar show={show} setShow={setShow}/>
      </div>
      <div className={show ? 'column2' : 'column2Expand'}>
        <div>
          <Header show={show} setShow={setShow}/>
        </div>
        <div>
          <Switch>
              <RoleGuard role={[User.Roles.NORMAL_USER]} exact path = {props.match.url+ '/'}     component = {Dashboard}    />
              <RoleGuard role={[User.Roles.NORMAL_USER]} path = {props.match.url+ '/composition'}     component = {Composition}    />
              <RoleGuard role={[User.Roles.NORMAL_USER]} path = {props.match.url+ '/profile'}     component = {Profile}    />
              <RoleGuard role={[User.Roles.ADMIN]} path = {props.match.url+ '/users'}     component = {Users}    />
              <RoleGuard role={[User.Roles.ADMIN]} path = {props.match.url+ '/adduser'}     component = {AddUser}    />
              <RoleGuard role={[User.Roles.NORMAL_USER]} path = {props.match.url+ '/addproduct'}     component = {AddProduct}    />
          </Switch>
        </div>
      </div>
      
    </div>
  )
}
