import React, { useContext, useEffect, useState } from 'react'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import AuthService from '../../services/Auth';
import { useFormik } from 'formik';
import { AuthVld } from '../../validation';
import { CircularProgress, createTheme } from '@mui/material';
import localforage from 'localforage';
import { UserContext } from '../../context/user.context';
import { PngIcons } from '../../icons';
import { ThemeProvider } from '@emotion/react';

export default function Login({setTitle}) {

    const userContext = useContext(UserContext);
    const history = useHistory();

    const [hide, setHide] = useState(true);
    
    const [state, setState] = useState({
        loader   : false,
        apiError : ''
      });
    
    const initState = {
        email           : '',
        password        : '',
    }


    const theme = createTheme({
        status: {
          danger: '#e53e3e',
        },
        palette: {
          primary: {
            main: '#3bafda',
            darker: '#3295b9',
            contrastText: '#fff',
          },
          neutral: {
            main: '#fff',
            contrastText: '#fff',
          },
        },
      });

    const handleSubmit = async () => {
        setState({loader: true});
        
        let payload = {
            email : formik.values.email,
            password : formik.values.password
        }

        const {error, response} = await AuthService.Login({payload});
        if(error) return setState({apiError: error.error, loader: false});
        else {
            localforage.setItem('user', response.data)
            localforage.setItem('token',response.data.token)
            userContext.updateUser(response.data)
            history.push('/dashboard');
            setState({...state, loader: false});
        }
    }

    const formik = useFormik({
        initialValues    : { ...initState },
        onSubmit         : handleSubmit,
        validationSchema : AuthVld.LoginVld,
        isInitialValid   : false
    })
    

    useEffect(()=>{
        setTitle('Login');
    },[])

  return (
    <div className='login'>
        <form onSubmit={formik.handleSubmit}>
            <div>
                    <label className='label text-start  w-100'>Email address</label>
                    <input 
                        type        ="email"
                        name        ="email" 
                        placeholder = "Enter your email"
                        value       = {formik.values.email}
                        onChange    = {formik.handleChange}
                        error       = {formik.touched.email && formik.errors.email}
                        helperText  = {formik.touched.email && formik.errors.email ? formik.errors.email : ''}
                    />
            </div>
            <div className='mt-2'>
                    <label className='label text-start  w-100'>Password</label>
                    <div className="position-relative">
                        <input 
                            type        = { hide ? "password" : "text"}
                            name        = "password" 
                            placeholder = "Enter your password"
                            value       = {formik.values.password}
                            onChange    = {formik.handleChange}
                            error       = {formik.touched.password && formik.errors.password}
                            helperText  = {formik.touched.password && formik.errors.password ? formik.errors.password : ''}
                        />
                        <div className='password' onClick={()=>{setHide(!hide)}}>
                            { hide ? <img src={PngIcons.view} width='18px' height='18px'/> : <img src={PngIcons.hide} width='18px' height='18px'/>}
                        </div>
                    </div>
            </div>

            <div className='mt-4'>
                <ThemeProvider theme={theme}>
                    <Button
                        style={{textTransform: 'none'}} 
                        className   ='btn'
                        variant     ="contained"
                        fullWidth   ={true}
                        color       ='primary'
                        type        ="submit"
                        startIcon   = {state.loader && <CircularProgress size={20} color={'inherit'}/>}

                    >Login</Button>
                </ThemeProvider>
            </div>
            <div className='mt-4 error'>{state.apiError}</div>
        </form>

        {/* <div className='p2 mt-5'>Don’t have an account?  <span className='sh2 cp' onClick={()=>{}}>Sign Up</span></div> */}
    </div>
  )
}
