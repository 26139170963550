import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom'
import { AuthService } from '../../services';
import Loader from '../../components/Loader';
import Table from '../../components/Table.tsx';
import { createTheme } from '@material-ui/core';
import { ThemeProvider } from '@emotion/react';

export default function Users() {

  const history = useHistory();
  const [users, setUsers] = useState();
  const [loader, setLoader] = useState(false);

  useEffect(async ()=>{
    setLoader(true);
    const {response, error} = await AuthService.Get();
    if(response){
      let usersData = [];
      for (const element of response.data) {
        usersData.push({
          ...element,
          role : element.types.includes(2) ? 'Admin' : 'Normal user',
          action :  <div><img src='/images/delete-button.png' className='cp' onClick={()=>{handleDelete(element._id)}} height={24} width={24}/>&nbsp;&nbsp;&nbsp;&nbsp;
                        <img src='/images/edit.png' className='cp'onClick={() => {history.push({pathname: '/dashboard/adduser', state: {user: {...element, edit: true}}})}} height={24} width={24}/></div>
        })
      }

      setUsers(usersData)
    }
    setLoader(false);
  },[])

  const handleDelete = async (_id) => {
    const {response, error} = await AuthService.Delete({payload: {_id: _id}});
    window.location.reload();
  }


  
  const columns = [
    {
        Header: 'User Email',
        accessor: 'email',
        sort: true,
    },
    {
        Header: 'Role',
        accessor: 'role',
        sort: true,
    },
    {
        Header: 'Created At',
        accessor: 'created',
        sort: true,
    },
    {
        Header: 'Action',
        accessor: 'action',
        sort: true,
    }
  ];

  const sizePerPageList = [
    {
        text: '5',
        value: 5,
    },
    {
      text: '8',
      value: 8,
    },
    {
        text: '10',
        value: 10,
    },
    {
        text: '25',
        value: 25,
    },
    {
        text: 'All',
        value: users ? users.length : '',
    },
  ];

  
  const theme = createTheme({
    status: {
      danger: '#e53e3e',
    },
    palette: {
      primary: {
        main: '#3bafda',
        darker: '#3295b9',
        contrastText: '#fff',
      },
      neutral: {
        main: '#fff',
        contrastText: '#fff',
      },
    },
  });

  return (
    <div className='users'>
      <div className='h4 sideheader'>User</div>
      {
          loader ? <Loader/> :
          <div className='content'>
            <div className='cardBack'>
            <ThemeProvider theme={theme}>
              <Button 
                style={{textTransform: 'none'}} 
                className   ='mt-4 btn'
                variant     ="contained"
                color       ='primary'
                fullWidth   = {true}
                onClick     = {
                  ()=> history.push('/dashboard/adduser')
                }
              >Add New User</Button> 
            </ThemeProvider>
              {users && users.length > 0 ?
                  <div className='mt-4'>
                    <Table
                      columns={columns}
                      data={users}
                      pageSize={5}
                      sizePerPageList={sizePerPageList}
                      isSortable={true}
                      pagination={true}
                      isSelectable={false}
                    />
                  </div>
                : ''
              }
            </div>
          </div>
      }
      
    </div>
  )
}
