const Roles = {
    ADMIN        : 2,
    MASTER_ADMIN : 1,
    NORMAL_USER  : 3
}

const UsedFor = {
    BUSINESS : 'business',
    TESTING  : 'testing'
}

const CreatedFor = {
    PERSONAL : 'personal',
    CLIENT   : 'client'
}

const User = {
    Roles,
    UsedFor,
    CreatedFor
}

export default User